/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { useTheme } from "../hooks/useTheme";
import { Paragraph } from "./text/Paragraph";
import { Svg } from "./Svg";
import { Header5 } from "./text/Header5";
import { Button } from "./Button";
import { useContactInformation } from "../hooks/useContactInformation";

const Reference = ({ title, source }) => {
  const { spacing } = useTheme();

  return (
    <div css={{ marginBottom: spacing.M }}>
      <Paragraph css={{ fontSize: "1.4rem", fontStyle: "italic" }}>
        {title}
      </Paragraph>
      <Paragraph css={{ fontSize: "1.4rem" }}>{source}</Paragraph>
    </div>
  );
};

const Header = ({ children }) => {
  const { spacing } = useTheme();

  return (
    <Header5 css={{ marginTop: spacing.XXL, marginBottom: spacing.M }}>
      {children}
    </Header5>
  );
};

const CalculationCollapse = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const { color, spacing } = useTheme();
  const { contactInformation } = useContactInformation();

  return (
    <div id="how-we-calculate" css={{ marginTop: spacing["5XL"] }}>
      <button
        css={{
          border: 0,
          background: "none",
          display: "flex",
          alignItems: "center",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "16px",
          transition: "all 0.3s",
          color: isCollapsed ? "#475867" : color.primary.main,
          padding: 0,
          cursor: "pointer",
          borderBottom: `2px solid ${color.primary.main}`,
          margin: "0 auto",

          "&:hover, &:focus": {
            color: color.primary.main,
          },
        }}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <Paragraph css={{ color: "inherit" }}>See how we calculate</Paragraph>
        <Svg
          name={isCollapsed ? "chevron-down" : "chevron-up"}
          css={{ color: "inherit", marginLeft: spacing.S }}
        />
      </button>
      {!isCollapsed && (
        <div css={{ marginTop: spacing.M }}>
          <Header>Introduction</Header>
          <Paragraph css={{ marginBottom: spacing.M }}>
            In order to calculate the return on investment (ROI), we rely on a
            number of estimations together with the input data you provide. The
            estimations are based on third party industry market research (links
            at the bottom), and our knowledge of our user base and the
            CLM-industry.
          </Paragraph>
          <Paragraph css={{ marginBottom: spacing.M }}>
            To provide you with reasonable ROI expectations and benefits, the
            model is built with cautious assumptions and the calculations always
            choose the options that are most cautious (i.e. promises smaller
            savings).
          </Paragraph>
          <Paragraph css={{ marginBottom: spacing.M }}>
            Note that the numbers presented to you may cover aspects you have
            not thought about before, such as contract lead times, hours spent
            contracting, or costs for contracting.
          </Paragraph>
          <Paragraph css={{ marginBottom: spacing.M }}>
            We present monetary sums that we estimate is the potential of what
            you can save, but we are very aware that it is highly unlikely you
            are facing such bills for your contracting on a monthly basis. Key
            elements of these costs are likely internal and thus less visible to
            you in your daily job. Surfacing hidden costs is one of the key
            benefits of doing a thorough ROI analysis, be it for a CLM tool or
            other investments. It’s not different from when you calculate the
            cost of driving a car; it is easy only to consider petrol but of
            course wear and tear, insurance, parking, depreciation, tax, your
            time, etc. should be considered to get a full picture.
          </Paragraph>
          <Paragraph css={{ marginBottom: spacing.M }}>
            Now, let’s dive deeper into the calculations.
          </Paragraph>
          <Header>Time per contract and time saved</Header>
          <Paragraph css={{ marginBottom: spacing.M }}>
            At the heart of everything is the estimated number of hours of work
            each contract needs over its lifecycle. The term <i>lifecycle</i> is
            important here since, apart from drafting and signing, many
            contracts need to be monitored post-signing, and they need to be
            easily identified and analyzed in an archive.
          </Paragraph>
          <Paragraph css={{ marginBottom: spacing.M }}>
            Our approach has been to consider two extremes to generate potential
            savings. The upper limit is a “manual and offline approach” to the
            various parts of contracting, and the lower limit is using the
            Precisely platform throughout the contract lifecycle. Depending on
            your current contracting processes, tools, and the types of
            contracts you have in place, we are able to estimate the current
            time needed for your average contract lifecycle. If your current
            setup is more traditional and offline, Precisely will be able to
            offer substantial savings. If you have already started with digital
            contracting and perhaps even some tools, Precisely will still be
            able to offer savings, but the impact might, of course, be less
            visible as you’ve already embarked on your digital transformation
            journey.
          </Paragraph>
          <Paragraph css={{ marginBottom: spacing.M }}>
            Our “manual and offline” reference case considers a complex
            contract, and if you’re not using any templates or tools, we
            estimate that to take 21 hours. Then, depending on the mix of
            contracts (simple, standard, or hard), the split of inbound and
            outbound contracts, and your current contracting setup, we end up
            with a number of average hours spent per contract. Some examples of
            calculations:
          </Paragraph>
          <ul
            css={{
              fontSize: "1.6rem",
              listStyleType: "disc",
              paddingLeft: spacing.XL,
              marginBottom: spacing.M,
            }}
          >
            <li>
              <Paragraph>
                A simple contract is only estimated to take 25% of the time of a
                complex contract,
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                If you already have an e-signing tool in place, your estimated
                time spent for review and signing drops by 75%,
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                For inbound contracts (not created by you), Precisely is only
                estimated to achieve up to 10% savings of that of a contract
                created by you.
              </Paragraph>
            </li>
          </ul>
          <Paragraph css={{ marginBottom: spacing.M }}>
            After you have filled in the relevant information about your current
            setup, we calculate an estimate for your current contracting
            workflow and compare that with what it would take using Precisely.
            That is how we reach the number of saved hours. The less advanced
            your current setup is, and the more complex contracts you create,
            the greater the number of hours saved.
          </Paragraph>

          <Header>Onboarding resources/Internal costs of implementation</Header>
          <Paragraph css={{ marginBottom: spacing.M }}>
            We want to be fully transparent and attribute the amount of effort
            we think is reasonable for you to get up to speed with a CLM like
            Precisely. Although a CLM is a fantastic tool, it is not magic and
            everyone will need a certain ramp-up time. This time depends on the
            scope of your implementation, and takes into account the time we
            think you’ll need to dedicate to setting yourself up in a CLM to
            achieve the savings we calculate.
          </Paragraph>
          <Paragraph css={{ marginBottom: spacing.M }}>
            This ranges from 4 to 40 hours in the first year, and is our
            estimation of time needed for things like: training, onboarding,
            setup, migration, and the initial time lost when you are not yet
            operating at “full Precisely speed.” In the calculation, you can see
            this highlighted as internal costs as it multiplies the sum of hours
            needed with the cost per hour.
          </Paragraph>

          <Header>Contract lead times</Header>
          <Paragraph css={{ marginBottom: spacing.M }}>
            Every contract has a lead time, from the first word on the draft all
            the way until it is signed. Anyone working with contracts knows this
            varies greatly with complexity and standardization. The way we
            calculate it is by using market research numbers for average
            contract lead times, depending on contract type, and the time that
            can be saved with an advanced CLM setup (market data suggests there
            are differences between poor and advanced setups ranging from 56 to
            147 days).
          </Paragraph>
          <Paragraph css={{ marginBottom: spacing.M }}>
            Using a CLM will reduce the part of contract lead time related to
            manual, routine, and repetitive work – this is where you will
            achieve savings. In addition, the way we calculate this saving also
            depends on your current contracting setup. The less advanced your
            setup is, and the more complex the contracts you create, the greater
            the lead time saving will be with Precisely.
          </Paragraph>

          <Header>Tool replacement</Header>
          <Paragraph css={{ marginBottom: spacing.M }}>
            There will be instances where you already have a tool, e.g.
            e-signing, that Precisely can replace. This should naturally
            generate a saving for you. However, knowing your specific cost in a
            general model is very hard. We therefore take a very conservative
            number for each possible tool ($100 - $300 per month total cost) but
            could naturally create a more specific model for your organization
            if you provide us with more accurate, and likely higher, costs.
          </Paragraph>

          <Header>The financials/ROI model</Header>
          <Paragraph css={{ marginBottom: spacing.M }}>
            Although we have seriously impressive churn and revenue growth
            numbers (very few of our clients ever leave and the average client
            increases their usage of Precisely by 47% in one year), we only
            create a 3-year ROI model. We also assume that a full roll-out would
            occur in the first year. Should you wish, we can create a more
            customized model upon your request.
          </Paragraph>

          <div>
            <a
              target="blank"
              href={`https://meetings.hubspot.com/precisely/roi-consultation/?email=${contactInformation?.email}`}
            >
              <Button size="large">Book an ROI consultation</Button>
            </a>
          </div>

          <Header>Final reflections on market numbers and estimations</Header>
          <Paragraph css={{ marginBottom: spacing.M }}>
            It is very hard to find one true number for the cost of a contract.
            Market research papers provide very varied average costs per
            contract ranging from $4,800 to $200,000. Based on our experience,
            we know that, in particular, those high numbers refer to contracts
            unlikely materially affected by a CLM tool as they likely require
            significant third party legal review. This is no doubt important,
            but not particularly relevant to our calculations. Should we use the
            generally agreed average costs for lawyers (approx. $130) or
            non-lawyers (approx. $65), the hours needed for a single contract
            would almost be 40 hours minimum. Now, we took the view that this
            number, which was primarily based on responses from $1B+
            enterprises, likely covers a lot more than just the CLM aspect of
            contracting, which we set out to calculate. This is why we opted for
            the more conservative approach we have described above.
          </Paragraph>

          <Header>References</Header>

          <Reference
            title="2021 State of the Industry Survey - Results and Analysis"
            source="2021, Corporate Legal Operations Consortium"
          />
          <Reference
            title="ACC Chief Legal Officers Survey"
            source="2021, Association of Corporate Counsel"
          />
          <Reference
            title="ACC Chief Legal Officers Survey"
            source="2022, Association of Corporate Counsel"
          />
          <Reference
            title="Global Legal Department Benchmarking Survey"
            source="2021 Report, KPMG Global Legal Services / KPMG Legal Operations Transformation Services"
          />
          <Reference
            title="How do you turn barriers into building blocks"
            source="2021, EY General Counsel Imperative Series, in collaboration with Harvard Law School"
          />
          <Reference
            title="How does contracting complexity hide clear profitability"
            source="2021 EY Law Survey, in collaboration with Harvard Law School"
          />
          <Reference
            title="Law Department Benchmark Report"
            source="2021, Association of Corporate Counsel"
          />
          <Reference
            title="Legal Department Operations (LDO) Index: The risk of being left behind"
            source="2021, Thomson Reuters Institute"
          />
          <Reference
            title="Modernizing Legal Services"
            source="2021, State of Legal Operation Survey, Deloitte"
          />
          <Reference
            title="The benefits of focus, the costs of neglect"
            source="2021, Benchmark report, World Commerce and Contracting"
          />
        </div>
      )}
    </div>
  );
};

export { CalculationCollapse };
