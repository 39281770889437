import { addOnSuggestions } from "../../data/addOnSuggestions";
import { serviceSuggestions } from "../../data/serviceSuggestions";
import { CostTable } from "../CostTable";
import { Question } from "../layout/Question";
import { Section } from "../layout/Section";

const PreciselySetupSection = ({
  userLicensesCostTable,
  addOnsCostTable,
  servicesCostTable,
}) => {
  return (
    <Section header="Recommended Precisely setup" subHeader="Step 4">
      <Question header="User licenses (monthly)">
        <CostTable {...userLicensesCostTable} />
      </Question>

      <Question header="Add-ons and Integrations (monthly)">
        <CostTable
          {...addOnsCostTable}
          suggestions={addOnSuggestions}
          isAddEnabled
        />
      </Question>

      <Question header="Additional services (one-time)">
        <CostTable
          {...servicesCostTable}
          suggestions={serviceSuggestions}
          isAddEnabled
        />
      </Question>
    </Section>
  );
};

export { PreciselySetupSection };
