/** @jsxImportSource @emotion/react */
import Slider from "rc-slider";
import { Input } from "./Input";
import { Paragraph } from "../text/Paragraph";
import { useTheme } from "../../hooks/useTheme";
import { useMediaQuery } from "../../hooks/useMediaQuery";

// Data point
const DataPointDisplay = ({ color, label, description, className }) => {
  const { spacing, color: themeColor } = useTheme();

  return (
    <div
      css={{ display: "flex", flex: 1, marginBottom: spacing.S }}
      className={className}
    >
      <div
        css={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          background: color,
          marginRight: spacing.M,
          marginTop: spacing.XS,
        }}
      />
      <div css={{ flex: 1 }}>
        <Paragraph css={{ marginBottom: spacing.XS }}>{label}</Paragraph>
        <Paragraph css={{ fontSize: "12px", color: themeColor.gray.medium }}>
          {description}
        </Paragraph>
      </div>
    </div>
  );
};

// Range slider
const RangeInput = ({ value, onChange, rangeStyles }) => {
  const { spacing, color } = useTheme();
  const { windowLessThan } = useMediaQuery();

  const minValue = 0;
  const maxValue = value.reduce((total, current) => total + current, 0);

  // Convert value ranges to points
  const points = value
    .slice(0, -1)
    .reduce(
      (currentPoints, currentValue) => {
        return [
          ...currentPoints,
          currentPoints[currentPoints.length - 1] + currentValue,
        ];
      },
      [0]
    )
    .concat([maxValue]);

  // Convert points to ranges
  const handleChange = (updatedPoints) => {
    const revPoints = updatedPoints.reverse().slice(0, -1);

    const uv = revPoints.map((point, index) => {
      return point - (revPoints[index + 1] ?? 0);
    });

    onChange(uv.reverse());
  };

  const rangesCss = rangeStyles.reduce((css, style, index) => {
    return {
      ...css,
      [`.rc-slider-track-${index + 1}`]: {
        backgroundColor: style.color,
      },
    };
  }, {});

  return (
    <div
      css={{
        margin: `${spacing.M} 0`,
        ...rangesCss,
      }}
    >
      <Slider
        range
        value={points}
        onChange={handleChange}
        min={minValue}
        max={maxValue}
        pushable={true}
        css={{
          marginBottom: spacing.XL,
          " .rc-slider-rail": {
            height: "5px",
          },
          " .rc-slider-track": {
            height: "5px",
          },
          " .rc-slider-handle": {
            borderColor: color.primary.main,
            width: "18px",
            height: "18px",
            marginTop: "-7px",
          },
          " .rc-slider-handle-dragging": {
            borderColor: `${color.primary.main} !important`,
            boxShadow: `0 0 0 5px rgba(37, 76, 216, 0.5) !important`, // TODO: From theme, HEX to rgba
          },
          " .rc-slider-dot-active": {
            borderColor: color.primary.main,
          },

          // Disable modify of first and last slider
          " .rc-slider-handle-1": {
            display: "none",
            pointerEvents: "none",
          },
          [` .rc-slider-handle-${points.length}`]: {
            display: "none",
            pointerEvents: "none",
          },
        }}
      />
      <div>
        {value.map((value, index) => (
          <div
            css={{
              display: "flex",
              flexDirection: windowLessThan.M ? "column" : "row",
              marginBottom: windowLessThan.M ? spacing.L : spacing.M,
            }}
            key={`input-${index}`}
          >
            <DataPointDisplay
              color={rangeStyles[index].color}
              label={rangeStyles[index].label}
              description={rangeStyles[index].description}
            />
            <div
              css={{
                display: "flex",
                alignItems: "center",
                marginLeft: spacing.XL,
              }}
            >
              <Input
                readOnly
                value={value}
                minValue={0}
                maxValue={maxValue}
                css={{ opacity: 0.5, marginRight: spacing.S }}
              />
              <Paragraph>%</Paragraph>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { RangeInput };
