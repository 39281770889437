import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "rc-slider/assets/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@emotion/react";
import { theme } from "./theme";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCXSkrEoqpPYivVAfdhxWVRlV2xrsKAHyY",
  authDomain: "precisely-savings-calculator.firebaseapp.com",
  projectId: "precisely-savings-calculator",
  storageBucket: "precisely-savings-calculator.appspot.com",
  messagingSenderId: "461444834410",
  appId: "1:461444834410:web:407ba29bee054f44221613",
  measurementId: "G-L0FHSX9JWW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
