const useFormatCurrency = () => {
  var currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    // currencyDisplay: "code",
  });

  const formatCurrency = (number) => currencyFormatter.format(number);

  return { formatCurrency };
};

export { useFormatCurrency };
