/** @jsxImportSource @emotion/react */
import { AutosuggestInput } from "./input/AutosuggestInput";
import { Button } from "./Button";
import { Input } from "./input/Input";
import { Paragraph } from "./text/Paragraph";
import { Svg } from "./Svg";
import { CostTableState } from "../types/CostTableState";
import { useTheme } from "../hooks/useTheme";
import { CostTableRow } from "../types/CostTableRow";
import { useFormatCurrency } from "../hooks/useFormatCurrency";

const CostTable = ({
  state,
  setTableRows,
  suggestions = [],
  isAddEnabled = false,
}: CostTableProps) => {
  const { spacing, color } = useTheme();
  const { formatCurrency } = useFormatCurrency();

  const { totalPrice, totalListPrice, totalSavings, tableRows } = state;

  const visibleSuggestions = suggestions.filter(
    (suggestion) => !tableRows.some((tableRow) => tableRow.id === suggestion.id)
  );

  const onAddClick = () =>
    setTableRows([
      ...tableRows,
      {
        id: String(tableRows.length + 1),
        name: "",
        amount: 1,
        listPrice: 0,
        discount: 0,
        isAddedManually: true,
      },
    ]);

  const onRemoveClick = (rowId) => {
    setTableRows(tableRows.filter(({ id }) => rowId !== id));
  };

  const onSuggestionSelect = (index: number, suggestion: CostTableRow) => {
    const updatedTableRows = [...tableRows];

    updatedTableRows[index] = { ...suggestion, isAddedManually: true };

    setTableRows(updatedTableRows);
  };

  const createInputEventHandler = (rowId, propertyName) => (event) => {
    const newValue = event?.target ? event.target.value : event;

    const convertedValue =
      event?.target?.type === "number" && newValue !== ""
        ? parseInt(newValue)
        : newValue;

    setTableRows(
      tableRows.map((tableRow) =>
        tableRow.id === rowId
          ? { ...tableRow, [propertyName]: convertedValue }
          : tableRow
      )
    );
  };

  return (
    <>
      <table
        css={{
          border: "1px solid #ededed",
          td: {
            border: "1px solid #ededed",
            " input": { borderRadius: 0, border: 0, width: "100%" },
          },
          th: { border: "1px solid #ededed", textAlign: "left" },
          borderCollapse: "collapse",

          width: "100%",
          marginTop: spacing.M,
          input: { margin: 0 },
        }}
      >
        <thead>
          <tr css={{ fontWeight: "bold" }}>
            <th css={{ padding: spacing.S, width: "30%" }}>
              <Paragraph isBold>License</Paragraph>
            </th>
            <th css={{ padding: spacing.S, width: "15%" }}>
              <Paragraph isBold>Amount</Paragraph>
            </th>
            <th css={{ padding: spacing.S, width: "20%" }}>
              <Paragraph isBold>Your price (USD)</Paragraph>
            </th>
            <th css={{ padding: spacing.S, width: "20%" }}>
              <Paragraph isBold>List price (USD)</Paragraph>
            </th>
            <th css={{ padding: spacing.S, width: "15%" }}>
              <Paragraph isBold>Discount (%)</Paragraph>
            </th>
          </tr>
        </thead>
        <tbody>
          {tableRows.map((tableRow, index) => (
            <tr
              key={tableRow.id}
              css={{ "&:hover": { " .row-controls": { display: "flex" } } }}
            >
              <td css={{ padding: isAddEnabled ? undefined : spacing.S }}>
                {isAddEnabled ? (
                  <AutosuggestInput
                    value={tableRow.name}
                    onChange={createInputEventHandler(tableRow.id, "name")}
                    suggestions={visibleSuggestions}
                    onSuggestionSelect={(suggestion) =>
                      onSuggestionSelect(index, suggestion)
                    }
                  />
                ) : (
                  <Paragraph>{tableRow.name}</Paragraph>
                )}
              </td>
              <td>
                <Input
                  type="number"
                  value={tableRow.amount}
                  onChange={createInputEventHandler(tableRow.id, "amount")}
                />
              </td>
              <td css={{ padding: spacing.S }}>
                <Paragraph
                  css={{ display: "flex", justifyContent: "space-between" }}
                >
                  {tableRow.price}
                  {tableRow.savings ? (
                    <span css={{ color: color.danger.dark }}>
                      {`(-${formatCurrency(tableRow.savings)})`}
                    </span>
                  ) : undefined}
                </Paragraph>
              </td>
              <td css={{ padding: isAddEnabled ? undefined : spacing.S }}>
                {isAddEnabled ? (
                  <Input
                    type="number"
                    value={tableRow.listPrice}
                    onChange={createInputEventHandler(tableRow.id, "listPrice")}
                  />
                ) : (
                  <Paragraph>{tableRow.listPrice}</Paragraph>
                )}
              </td>
              <td css={{ position: "relative" }}>
                <Input
                  type="number"
                  value={tableRow.discount}
                  onChange={createInputEventHandler(tableRow.id, "discount")}
                />
                {isAddEnabled && (
                  <div
                    className="row-controls"
                    css={{
                      position: "absolute",
                      right: "-32px",
                      opacity: 0.5,
                      cursor: "pointer",
                      width: "32px",
                      height: "32px",
                      top: "0",
                      display: "none",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => onRemoveClick(tableRow.id)}
                  >
                    <Svg name="remove" css={{}} />
                  </div>
                )}
              </td>
            </tr>
          ))}
          <tr>
            <td css={{ padding: spacing.S }}>
              <Paragraph isBold>Total</Paragraph>
            </td>
            <td></td>
            <td css={{ padding: spacing.S }}>
              <Paragraph
                isBold
                css={{ display: "flex", justifyContent: "space-between" }}
              >
                {formatCurrency(totalPrice)}
                {totalSavings ? (
                  <span css={{ color: color.danger.dark }}>
                    (-{formatCurrency(totalSavings)})
                  </span>
                ) : undefined}
              </Paragraph>
            </td>
            <td css={{ padding: spacing.S }}>
              <Paragraph>{formatCurrency(totalListPrice)}</Paragraph>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      {isAddEnabled && (
        <Button onClick={onAddClick} css={{ marginTop: spacing.S }}>
          Add
        </Button>
      )}
    </>
  );
};

type CostTableProps = {
  state: CostTableState;
  setTableRows: (value: CostTableRow[]) => void;
  suggestions?: CostTableRow[];
  isAddEnabled?: boolean;
};

export { CostTable };
