/** @jsxImportSource @emotion/react */
import InlineSvg from "react-inlinesvg";

const preProcessSvgs = (code) =>
  code
    .replace(/fill=".*?"/g, 'fill="currentColor"')
    .replace(/class=".*?"/g, "")
    .replace(/width=".*?"/g, "")
    .replace(/height=".*?"/g, "");

const DIMENSION = "18px";

const Svg = ({ ariaLabel, name, className }: SvgProps) => {
  const path = require(`../assets/img/icons/${name}.svg`);

  return (
    <InlineSvg
      css={{
        minWidth: DIMENSION,
        minHeight: DIMENSION,
        width: DIMENSION,
        height: DIMENSION,
        color: "#8596a6",

        "& svg": {
          width: DIMENSION,
          height: DIMENSION,
        },
        "& path": {
          fill: "currentColor",
        },
      }}
      className={className}
      name={name}
      src={path}
      description={ariaLabel}
      cacheRequests
      preProcessor={preProcessSvgs}
    />
  );
};

type SvgProps = {
  name: string;
  ariaLabel?: string;
  className?: string;
};

export { Svg };
