import { CostTableRow } from "../types/CostTableRow";

export const serviceSuggestions: CostTableRow[] = [
  {
    id: "onboarding_basic",
    name: "Onboarding - Basic",
    listPrice: 0,
    discount: 0,
    amount: 1,
  },
  {
    id: "onboarding_advanced",
    name: "Onboarding - Advanced",
    listPrice: 995,
    discount: 0,
    amount: 1,
  },
  {
    id: "onboarding_premium",
    name: "Onboarding - Premium",
    listPrice: 2995,
    discount: 0,
    amount: 1,
  },
  {
    id: "workflow_automation_basic",
    name: "Workflow Automation - Basic",
    listPrice: 0,
    discount: 0,
    amount: 1,
  },
  {
    id: "workflow_automation_advanced",
    name: "Workflow Automation - Advanced",
    listPrice: 995,
    discount: 0,
    amount: 1,
  },
  {
    id: "workflow_automation_premium",
    name: "Workflow Automation - Premium",
    listPrice: 2595,
    discount: 0,
    amount: 1,
  },
  {
    id: "migration_basic",
    name: "Migration - Basic",
    listPrice: 295,
    discount: 0,
    amount: 1,
  },
  {
    id: "migration_advanced",
    name: "Migration - Advanced",
    listPrice: 2945,
    discount: 0,
    amount: 1,
  },
  {
    id: "migration_premium",
    name: "Migration - Premium",
    listPrice: 3245,
    discount: 0,
    amount: 1,
  },
  {
    id: "salesforce_basic",
    name: "SalesForce - Basic",
    listPrice: 395,
    discount: 0,
    amount: 1,
  },
  {
    id: "salesforce_advanced",
    name: "SalesForce - Advanced",
    listPrice: 1595,
    discount: 0,
    amount: 1,
  },
  {
    id: "ad_implementation",
    name: "Active Directory (AD) - Implementation",
    listPrice: 195,
    discount: 0,
    amount: 1,
  },
  {
    id: "sso_implementation",
    name: "Single Sign-On (SSO) - Implementation",
    listPrice: 195,
    discount: 0,
    amount: 1,
  },
];
