import { CostTableRow } from "../types/CostTableRow";

export const licenseSuggestions: CostTableRow[] = [
  {
    id: "admin",
    name: "Admin",
    listPrice: 119,
    discount: 0,
    amount: 1,
  },
  {
    id: "manager",
    name: "Manager",
    listPrice: 99,
    discount: 0,
    amount: 1,
  },
  {
    id: "member",
    name: "Member",
    listPrice: 69,
    discount: 0,
    amount: 1,
  },
  {
    id: "viewer",
    name: "Viewer",
    listPrice: 10,
    discount: 0,
    amount: 1,
  },
];
