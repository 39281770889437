/** @jsxImportSource @emotion/react */
import Slider from "rc-slider";
import { Input } from "./Input";
import { Paragraph } from "../text/Paragraph";
import { MarkObj } from "rc-slider/lib/Marks";
import { useTheme } from "../../hooks/useTheme";
import { useMediaQuery } from "../../hooks/useMediaQuery";

const SliderInput = ({
  value,
  onChange,
  unit,
  minValue = 0,
  maxValue,
  marks,
}: SliderInputProps) => {
  const { spacing, color } = useTheme();
  const { windowLessThan } = useMediaQuery();

  const isMobileLayout = windowLessThan.M;
  const hasMarks = !!marks;

  const isMobileMarkLayout = isMobileLayout && hasMarks;

  return (
    <div>
      <div
        css={{
          display: "flex",
          flexDirection: isMobileMarkLayout ? "column" : "row",
          alignItems: "center",
        }}
      >
        <Slider
          value={value}
          onChange={onChange as (value: number | number[]) => void}
          min={minValue}
          max={maxValue}
          css={{
            " .rc-slider-rail": {
              height: "5px",
            },
            " .rc-slider-track": {
              background: color.primary.main,
              height: "5px",
            },
            " .rc-slider-handle": {
              borderColor: color.primary.main,
              width: "18px",
              height: "18px",
              marginTop: "-7px",
            },
            " .rc-slider-handle-dragging": {
              borderColor: `${color.primary.main} !important`,
              boxShadow: `0 0 0 5px rgba(37, 76, 216, 0.5) !important`, // TODO: From theme, HEX to rgba
            },
            " .rc-slider-dot-active": {
              borderColor: color.primary.main,
            },
          }}
          marks={marks}
        />
        <div
          css={{
            display: "flex",
            alignItems: "center",
            width: isMobileMarkLayout ? "100%" : undefined,
            marginTop: isMobileMarkLayout ? spacing.XXL : undefined,
          }}
        >
          <Input
            type="number"
            value={value}
            min={minValue}
            max={maxValue}
            onChange={(event) => onChange(parseInt(event.target.value))}
            css={{
              margin: `0 ${spacing.M}`,
              marginLeft: isMobileMarkLayout ? 0 : undefined,
              flexGrow: 1,
            }}
          />
          <Paragraph css={{ color: color.gray.medium }}>{unit}</Paragraph>
        </div>
      </div>
    </div>
  );
};

type SliderInputProps = {
  value: number;
  onChange: (value: number) => void;
  unit: string;
  minValue?: number;
  maxValue: number;
  marks?: Record<string | number, React.ReactNode | MarkObj>;
};

export { SliderInput };
