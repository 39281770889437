/** @jsxImportSource @emotion/react */
import { useCallback, useState } from "react";
import Autosuggest from "react-autosuggest";
import { Input } from "./Input";
import { Paragraph } from "../text/Paragraph";

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => {
  return (
    <Paragraph
      css={{
        background: "white",
        padding: "8px",
        cursor: "pointer",
        ":hover": { background: "#ededed" },
      }}
    >
      {suggestion.name}
    </Paragraph>
  );
};

const getSuggestions = (suggestions, value = "") => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? suggestions
    : suggestions.filter(
        (suggestion) =>
          suggestion.name.toLowerCase().slice(0, inputLength) === inputValue
      );
};

const AutosuggestInput = ({
  value,
  onChange,
  suggestions,
  onSuggestionSelect,
  className,
}: AutosuggestInputProps) => {
  const [activeSuggestions, setActiveSuggestions] = useState([]);

  const onInputChange = useCallback(
    (_, { newValue }) => onChange(newValue),
    [onChange]
  );

  const inputProps = {
    placeholder: "Name...",
    value,
    onChange: onInputChange,
  };

  const onSuggestionsFetchRequested = ({ value }) =>
    setActiveSuggestions(getSuggestions(suggestions, value));

  const onSuggestionsClearRequested = () => setActiveSuggestions([]);

  const renderInputComponent = (inputProps) => (
    <Input {...inputProps} css={className} />
  );

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      <div {...containerProps} css={{ position: "absolute", width: "260px" }}>
        {children}
      </div>
    );
  };

  return (
    <Autosuggest
      css={{ border: "1px solid red" }}
      suggestions={activeSuggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={(_, { suggestion }) =>
        onSuggestionSelect(suggestion)
      }
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      shouldRenderSuggestions={() => true}
    />
  );
};

type AutosuggestInputProps = {
  value: string;
  onChange: (value: string) => void;
  className?: string;
  suggestions: any[];
  onSuggestionSelect?: any;
};

export { AutosuggestInput };
