import { CheckboxGroupInput } from "../input/CheckboxGroupInput";
import { Question } from "../layout/Question";
import { Section } from "../layout/Section";
import { SplitSections } from "../layout/SplitSections";

const CurrentSolutionSection = ({
  archivingCheckboxGroup,
  templatingCheckboxGroup,
  signingCheckboxGroup,
  postSigningCheckboxGroup,
  integrationsCheckboxGroup,
}) => {
  return (
    <Section
      header="Current processes and software"
      subHeader="Step 2"
      description="By understanding your current process, workflows, and software, the calculator can estimate potential savings and efficiencies a CLM can provide. Fill in as much known information as possible."
    >
      <SplitSections
        sectionA={
          <Question
            header="Archiving"
            description="Categorization and tagging of contracts to easily find and analyze single or groups of contracts. For example: contracts with a certain clause, in a certain country, with a certain risk profile, for a specific part of your business, etc."
          >
            <CheckboxGroupInput {...archivingCheckboxGroup} />
          </Question>
        }
        sectionB={
          <Question
            header="Draft and Create"
            description="Managing and creating templates and potential access policies and clause libraries. Drafting contracts, either from scratch or based on templates. Reviewing/redlining internal and external contracts."
          >
            <CheckboxGroupInput {...templatingCheckboxGroup} />
          </Question>
        }
      />

      <SplitSections
        sectionA={
          <Question
            header="Review and Sign"
            description="Sending out contracts for execution, chasing signature(s), and updating versions as needed."
          >
            <CheckboxGroupInput {...signingCheckboxGroup} />
          </Question>
        }
        sectionB={
          <Question
            header="Post-signing"
            description="Oversight and monitoring key contract elements, e.g. important dates, changes, payment clauses, volumes, etc."
          >
            <CheckboxGroupInput {...postSigningCheckboxGroup} />
          </Question>
        }
      />

      <Question
        header="Integrations"
        description="Other tools you use today as part of your commercial/contracting workflow."
      >
        <CheckboxGroupInput {...integrationsCheckboxGroup} />
      </Question>
    </Section>
  );
};

export { CurrentSolutionSection };
