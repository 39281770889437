/** @jsxImportSource @emotion/react */
import { MainLayout } from "./components/layout/MainLayout";
import { useRoiCalculation } from "./hooks/useRoiCalculation";
import { useRoiCalculatorInputs } from "./hooks/useRoiCalculatorInputs";
import { ContractsSection } from "./components/sections/ContractsSection";
import { CurrentSolutionSection } from "./components/sections/CurrentSolutionSection";
import { TeamSection } from "./components/sections/TeamSection";
import { PreciselySetupSection } from "./components/sections/PreciselySetupSection";
import { InternalRoiSection } from "./components/sections/InternalRoiSection";
import { Button } from "./components/Button";
import { useContactInformation } from "./hooks/useContactInformation";
import { useHubSpotForm } from "./hooks/useHubSpotForm";
import { PaywallPage } from "./pages/Paywall";

const CalculatorInternal = () => {
  const {
    monthlyContracts,
    setMonthlyContracts,
    outboundContractsPercentage,
    setOutboundContractsPercentage,
    contractMix,
    setContractMix,
    contractorRate,
    setContractorRate,
    adminSlider,
    setAdminSlider,
    managerSlider,
    setManagerSlider,
    memberSlider,
    setMemberSlider,
    viewerSlider,
    setViewerSlider,
    archivingCheckboxGroup,
    templatingCheckboxGroup,
    signingCheckboxGroup,
    postSigningCheckboxGroup,
    integrationsCheckboxGroup,
    userLicensesCostTable,
    addOnsCostTable,
    servicesCostTable,
  } = useRoiCalculatorInputs();

  // BUSINESS CALCULATIONS
  const roiCalculation = useRoiCalculation({
    monthlyUserLicenseCost: userLicensesCostTable.state.totalPrice,
    monthlyAddOnLicenses: addOnsCostTable.state.tableRows.length,
    monthlyAddOnLicenseCost: addOnsCostTable.state.totalPrice,
    outboundContractsPercentage,

    serviceCost: servicesCostTable.state.totalPrice,
    userLicenses: {
      admin:
        userLicensesCostTable.state.tableRows.find(({ id }) => id === "admin")
          ?.amount ?? 0,
      manager:
        userLicensesCostTable.state.tableRows.find(({ id }) => id === "manager")
          ?.amount ?? 0,
      member:
        userLicensesCostTable.state.tableRows.find(({ id }) => id === "member")
          ?.amount ?? 0,
      viewer:
        userLicensesCostTable.state.tableRows.find(({ id }) => id === "viewer")
          ?.amount ?? 0,
    },
    internalUserRate: contractorRate,
    currentSolution: {
      archiving: archivingCheckboxGroup.state,
      templating: templatingCheckboxGroup.state,
      signing: signingCheckboxGroup.state,
      postSigning: postSigningCheckboxGroup.state,
    },
    contracts: {
      monthlyContracts: monthlyContracts,
      percentOfSimple: contractMix[0],
      percentOfStandard: contractMix[1],
      percentOfComplex: contractMix[2],
    },
  });

  // CONTACT INFORMATION SUBMIT
  const hubSpotForm = useHubSpotForm();

  const { contactInformation, updateContactInformation } =
    useContactInformation();

  const handleContactInformationSubmit = (contact) => {
    hubSpotForm.submit({
      onSuccess: () => updateContactInformation(contact),
      data: { contact, isInternal: true },
    });
  };

  const handleRoiSubmit = () => {
    hubSpotForm.submit({
      onSuccess: () => alert("Successfully saved data!"),
      data: {
        isInternal: true,
        contact: contactInformation,
        roi: {
          contracts: {
            monthlyContracts: monthlyContracts,
            percentOfOutbound: outboundContractsPercentage,
            percentOfSimple: contractMix[0],
            percentOfStandard: contractMix[1],
            percentOfComplex: contractMix[2],
          },
          currentSolution: {
            archiving: archivingCheckboxGroup.state,
            templating: templatingCheckboxGroup.state,
            signing: signingCheckboxGroup.state,
            postSigning: postSigningCheckboxGroup.state,
            integrations: integrationsCheckboxGroup.state,
          },
          team: {
            hourlyRate: contractorRate,
            admins: adminSlider,
            managers: managerSlider,
            members: memberSlider,
            viewers: viewerSlider,
          },
          package: {
            userLicenses: JSON.stringify(userLicensesCostTable.state),
            addOns: JSON.stringify(addOnsCostTable.state),
            services: JSON.stringify(servicesCostTable.state),
          },
          output: {
            contractTimeWithCurrentSolution:
              roiCalculation.averageTimePerOutboundContractWithCurrentSolution,
            contractTimeWithPrecisely:
              roiCalculation.averageTimePerOutboundContractingWithPrecisely,
            monthlyTotalSavings: roiCalculation.monthly.savings.totalSavings,
            monthlyHoursSaved: roiCalculation.monthly.hoursSaved,
            breakEvenMonth: roiCalculation.breakEvenMonth,
            roiYear1: roiCalculation.years[1].cumulativeReturnOnInvestment,
            roiYear2: roiCalculation.years[2].cumulativeReturnOnInvestment,
            roiYear3: roiCalculation.years[3].cumulativeReturnOnInvestment,
            contractLeadTimeSavings: roiCalculation.contractLeadTimeDaysSavings,
            annualNetResult: roiCalculation.monthly.savings.netSavings * 12,
          },
        },
      },
    });
  };

  if (!contactInformation) {
    return <PaywallPage onSubmit={handleContactInformationSubmit} />;
  }

  return (
    <MainLayout>
      <ContractsSection
        monthlyContracts={monthlyContracts}
        setMonthlyContracts={setMonthlyContracts}
        outboundContractsPercentage={outboundContractsPercentage}
        setOutboundContractsPercentage={setOutboundContractsPercentage}
        contractMix={contractMix}
        setContractMix={setContractMix}
      />

      <CurrentSolutionSection
        archivingCheckboxGroup={archivingCheckboxGroup}
        templatingCheckboxGroup={templatingCheckboxGroup}
        signingCheckboxGroup={signingCheckboxGroup}
        postSigningCheckboxGroup={postSigningCheckboxGroup}
        integrationsCheckboxGroup={integrationsCheckboxGroup}
      />

      <TeamSection
        adminSlider={adminSlider}
        setAdminSlider={setAdminSlider}
        managerSlider={managerSlider}
        setManagerSlider={setManagerSlider}
        memberSlider={memberSlider}
        setMemberSlider={setMemberSlider}
        viewerSlider={viewerSlider}
        setViewerSlider={setViewerSlider}
        contractorRate={contractorRate}
        setContractorRate={setContractorRate}
      />

      <PreciselySetupSection
        userLicensesCostTable={userLicensesCostTable}
        addOnsCostTable={addOnsCostTable}
        servicesCostTable={servicesCostTable}
      />

      <InternalRoiSection roiCalculation={roiCalculation} />

      <Button size="large" onClick={handleRoiSubmit}>
        Save data
      </Button>
    </MainLayout>
  );
};

export { CalculatorInternal };
