// DO NOT TOUCH - these keys must match with the current solution ids.
const TOOL_COST = {
  e_signing_software: 100,
  templating_software: 300,
  archiving_software: 200,
  post_signing_software: 100,
};

const useToolReplacementCalculation = ({
  currentSolution,
}: {
  currentSolution: { [key: string]: any[] };
}) => {
  let monthlyToolReplacementSavings = 0;

  Object.values(currentSolution).forEach((checkboxes) => {
    checkboxes.forEach((checkbox) => {
      if (checkbox.isChecked) {
        const toolCost = TOOL_COST[checkbox.id];

        if (toolCost) {
          monthlyToolReplacementSavings += toolCost;
        }
      }
    });
  });

  return { monthlyToolReplacementSavings };
};

export { useToolReplacementCalculation };
