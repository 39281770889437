/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";
import { Button } from "../Button";
import { InfoGraphic } from "../InfoGraphic";
import { Section } from "../layout/Section";
import { SplitSections } from "../layout/SplitSections";

const ExternalRoiSection = ({ roiCalculation, isDataVisible, onShowData }) => {
  const { formatCurrency } = useFormatCurrency();
  const { color } = useTheme();

  return (
    <Section
      header="Precisely benefits and Return on Investment"
      description={
        <span>
          Click{" "}
          <strong css={{ borderBottom: `2px solid ${color.gray.medium}` }}>
            Show result
          </strong>{" "}
          in order to see what your organization can save by using Precisely as
          a Contract Lifecycle Management software. The results will also be
          sent to you by email.
        </span>
      }
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {!isDataVisible && (
          <div css={{ zIndex: 9, position: "absolute" }}>
            <Button size="large" onClick={onShowData}>
              SHOW RESULT
            </Button>
          </div>
        )}
        <div
          css={{
            filter: isDataVisible ? undefined : "blur(10px)",
            transform: "translate3d(0, 0, 0)",
            transition: "0.5s filter linear",
          }}
        >
          <SplitSections
            sectionA={
              <>
                <InfoGraphic
                  amount={roiCalculation.breakEvenMonth}
                  unit="Month"
                  heading="Break even month"
                  description={`You are estimated to reach break even in month ${roiCalculation.breakEvenMonth} with Precisely.`}
                />
                <InfoGraphic
                  amount={roiCalculation.hoursSavedPerYear}
                  unit="Hours"
                  heading="Hours freed up per year"
                  description={`You are estimated to free up ${roiCalculation.hoursSavedPerYear} hours per year from your contracting lifecycle.`}
                />
              </>
            }
            sectionB={
              <>
                <InfoGraphic
                  amount={roiCalculation.monthly.savings.totalSavings * 12}
                  unit="USD"
                  heading="Money saved per year"
                  description={`It is estimated that, each year, your organization will save ${formatCurrency(
                    roiCalculation.monthly.savings.totalSavings * 12
                  )} USD.`}
                />
                <InfoGraphic
                  amount={roiCalculation.contractLeadTimeDaysSavings}
                  unit="Days"
                  heading="Shorter average contract lead time"
                  description={`You are estimated to save an average of ${roiCalculation.contractLeadTimeDaysSavings} days in contract lead time per contract.`}
                />
              </>
            }
          />
          <SplitSections
            sectionA={
              <InfoGraphic
                amount={roiCalculation.years[3].cumulativeReturnOnInvestment}
                unit="%"
                heading="3 Year Return on Investment (ROI)"
                description={`It is estimated that your return on your investment will be ${roiCalculation.years[3].cumulativeReturnOnInvestment}% in 3 years.`}
              />
            }
            sectionB={<></>}
          />
        </div>
      </div>
    </Section>
  );
};

export { ExternalRoiSection };
