import { useCallback, useState } from "react";
import { CostTableRow } from "../types/CostTableRow";
import { CostTableState } from "../types/CostTableState";

const calculateTableRows = (tableRows: CostTableRow[]): CostTableRow[] =>
  tableRows.map((tableRow) => {
    const savings = Math.round(
      (tableRow.listPrice || 0) * ((tableRow.discount || 0) / 100)
    );

    return {
      ...tableRow,
      price: (tableRow.listPrice ?? 0) - (savings ?? 0),
      savings,
    };
  });

const calculatePriceState = (tableRows: CostTableRow[]) => {
  let totalPrice = 0;
  let totalListPrice = 0;
  let totalSavings = 0;

  tableRows.forEach(({ amount, price, listPrice, savings }) => {
    totalPrice += amount * (price ?? 0);
    totalListPrice += amount * listPrice;
    totalSavings += amount * (savings ?? 0);
  });

  return { totalPrice, totalListPrice, totalSavings };
};

const useCostTable = (initialTableRows: CostTableRow[]) => {
  const [tableRowState, setTableRowState] = useState(
    calculateTableRows(initialTableRows)
  );

  const [totalPriceState, setTotalPriceState] = useState(
    calculatePriceState(tableRowState)
  );

  const setTableRows = useCallback(
    (
      tableRows:
        | CostTableRow[]
        | ((currentRows: CostTableRow[]) => CostTableRow[])
    ) => {
      setTableRowState((previousTableRows) => {
        let calculatedTableRows: CostTableRow[] = [];

        if (typeof tableRows === "function") {
          calculatedTableRows = calculateTableRows(
            tableRows(previousTableRows)
          );
        } else {
          calculatedTableRows = calculateTableRows(tableRows);
        }

        setTotalPriceState(calculatePriceState(calculatedTableRows));

        return calculatedTableRows;
      });
    },
    []
  );

  const state: CostTableState = {
    tableRows: tableRowState,
    ...totalPriceState,
  };

  return {
    state,
    setTableRows,
  };
};

export { useCostTable };
