import { useEffect } from "react";
import { CostTableRow } from "../types/CostTableRow";
import { useCostTable } from "./useCostTable";

const updateTableRow = (
  tableRows: CostTableRow[],
  id: string,
  property: string,
  value: number
) =>
  tableRows.map((tableRow) =>
    tableRow.id === id ? { ...tableRow, [property]: value } : tableRow
  );

const useSliderCostTableRowSync = (
  sliderValue: number,
  costTable: ReturnType<typeof useCostTable>,
  tableRowId: string,
  tableRowProperty: string
) => {
  const { setTableRows } = costTable;

  useEffect(
    () =>
      setTableRows((tableRows) =>
        updateTableRow(tableRows, tableRowId, tableRowProperty, sliderValue)
      ),
    [sliderValue, tableRowId, tableRowProperty, setTableRows]
  );
};

export { useSliderCostTableRowSync };
