import { useState } from "react";

type ContactInformation = {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
};

const CONTACT_INFORMATION_KEY = "contact_information";

const getStoredContactInformation = () => {
  const contactInformationFromStorage = sessionStorage.getItem(
    CONTACT_INFORMATION_KEY
  );

  return contactInformationFromStorage
    ? JSON.parse(contactInformationFromStorage)
    : undefined;
};

const useContactInformation = () => {
  const [contactInformation, setContactInformation] = useState<
    ContactInformation | undefined
  >(getStoredContactInformation());

  const updateContactInformation = (
    updatedContactInformation: ContactInformation
  ) => {
    sessionStorage.setItem(
      CONTACT_INFORMATION_KEY,
      JSON.stringify(updatedContactInformation)
    );

    setContactInformation(updatedContactInformation);
  };

  return { contactInformation, updateContactInformation };
};

export { useContactInformation };
