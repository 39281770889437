// CONFIG
const FORM_ID_CALCULATOR = "390afa20-0917-4e88-8963-ba93ed1b5927";
const FORM_ID_CONTACT_INFO = "f84f4b32-189a-49e1-8a98-ec57cfaadf3b";
const PORTAL_ID = 8805665;

// Format a list of checkbox answers to semi-coma separated values
const formatCheckboxAnswer = (state) =>
  state
    .filter(({ isChecked }) => isChecked)
    .map(({ id }) => id)
    .join(";");

// Main hook for interacting with the HubSpot form.
const useHubSpotForm = (isCalculatorForm = false) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const utmCampaign = urlParams.get("utm_campaign");

  const submit = ({
    onSuccess,
    data: {
      contact: { email, company, firstName, lastName },
      roi,
      isInternal,
    },
  }: {
    onSuccess?: () => void;
    data: {
      contact: any;
      roi?: any;
      isInternal: boolean;
    };
  }) => {
    const formData = {
      fields: [
        // Contact information
        ...(utmCampaign
          ? [
              {
                objectTypeId: "0-1",
                name: "utm_campaign",
                value: utmCampaign,
              },
            ]
          : []),
        {
          objectTypeId: "0-1",
          name: "email",
          value: email,
        },
        {
          objectTypeId: "0-1",
          name: "roi_calculator_v1__input__source",
          value: isInternal ? "internal" : "external",
        },
        ...(roi
          ? [
              // INPUT
              // Contracts
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__monthly_contracts",
                value: roi.contracts.monthlyContracts,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__outbound_contracts_percentage",
                value: roi.contracts.percentOfOutbound,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__simple_contracts_percentage",
                value: roi.contracts.percentOfSimple,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__standard_contracts_percentage",
                value: roi.contracts.percentOfStandard,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__complex_contracts_percentage",
                value: roi.contracts.percentOfComplex,
              },
              // Current solution
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__current_solution_archiving",
                value: formatCheckboxAnswer(roi.currentSolution.archiving),
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__current_solution_templating",
                value: formatCheckboxAnswer(roi.currentSolution.templating),
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__current_solution_signing",
                value: formatCheckboxAnswer(roi.currentSolution.signing),
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__current_solution_post_signing",
                value: formatCheckboxAnswer(roi.currentSolution.postSigning),
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__current_solution_integrations",
                value: formatCheckboxAnswer(roi.currentSolution.integrations),
              },
              // Team
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__team_hourly_rate",
                value: roi.team.hourlyRate,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__team_admins",
                value: roi.team.admins,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__team_managers",
                value: roi.team.managers,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__team_members",
                value: roi.team.members,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__input__team_viewers",
                value: roi.team.viewers,
              },
              // Precisely package data dump
              ...(roi.package
                ? [
                    {
                      objectTypeId: "0-1",
                      name: "roi_calculator_v1__input__user_licenses",
                      value: roi.package.userLicenses,
                    },
                    {
                      objectTypeId: "0-1",
                      name: "roi_calculator_v1__input__add_ons",
                      value: roi.package.addOns,
                    },
                    {
                      objectTypeId: "0-1",
                      name: "roi_calculator_v1__input__services",
                      value: roi.package.services,
                    },
                  ]
                : []),
              // OUTPUT
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__output__contract_time_with_current_solution",
                value: roi.output.contractTimeWithCurrentSolution,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__output__contract_time_with_precisely",
                value: roi.output.contractTimeWithPrecisely,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__output__monthly_total_savings",
                value: roi.output.monthlyTotalSavings,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__output__monthly_hours_saved",
                value: roi.output.monthlyHoursSaved,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__output__break_even_month",
                value: roi.output.breakEvenMonth,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__output__roi_year_1",
                value: roi.output.roiYear1,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__output__roi_year_2",
                value: roi.output.roiYear2,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__output__roi_year_3",
                value: roi.output.roiYear3,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_output___contract_lead_time_savings",
                value: roi.output.contractLeadTimeSavings,
              },
              {
                objectTypeId: "0-1",
                name: "roi_calculator_v1__output__annual_net_result",
                value: roi.output.annualNetResult,
              },
            ]
          : [
              // The contact form has been submitted
              {
                objectTypeId: "0-1",
                name: "company",
                value: company,
              },
              {
                objectTypeId: "0-1",
                name: "firstname",
                value: firstName,
              },
              {
                objectTypeId: "0-1",
                name: "lastname",
                value: lastName,
              },
            ]),
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
    };

    const formattedData = JSON.stringify(formData);

    // Either they submit contact information only, or the roi form
    const formUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${
      roi ? FORM_ID_CALCULATOR : FORM_ID_CONTACT_INFO
    }`;

    fetch(formUrl, {
      method: "POST",
      body: formattedData,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((err) => {
        // if any error occured, then catch it here
        console.error(err);
      });
  };

  return { submit };
};

export { useHubSpotForm };
