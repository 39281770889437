/** @jsxImportSource @emotion/react */

import { useTheme } from "@emotion/react";
import { useFormatCurrency } from "../hooks/useFormatCurrency";
import { Paragraph } from "./text/Paragraph";

const MonthlySavingsTable = ({
  contractingSavings,
  toolReplacementSavings,
  netSavings,
}: {
  contractingSavings: number;
  toolReplacementSavings: number;
  netSavings: number;
}) => {
  const { spacing } = useTheme();
  const { formatCurrency } = useFormatCurrency();

  return (
    <table css={{ width: "100%" }}>
      <tbody>
        <tr>
          <td css={{ padding: `${spacing.S} 0` }}>
            <Paragraph>Contracting</Paragraph>
          </td>
          <td css={{ padding: `${spacing.S} 0`, textAlign: "right" }}>
            <Paragraph>{formatCurrency(contractingSavings)}</Paragraph>
          </td>
        </tr>
        <tr>
          <td css={{ padding: `${spacing.S} 0` }}>
            <Paragraph>Tool replacement</Paragraph>
          </td>
          <td css={{ padding: `${spacing.S} 0`, textAlign: "right" }}>
            <Paragraph>{formatCurrency(toolReplacementSavings)}</Paragraph>
          </td>
        </tr>

        <tr css={{ borderTop: "1px solid #5D6F7E" }}>
          <td css={{ padding: `${spacing.S} 0` }}>
            <Paragraph isBold>Monthly net savings</Paragraph>
          </td>
          <td css={{ padding: `${spacing.S} 0`, textAlign: "right" }}>
            <Paragraph isBold>{formatCurrency(netSavings)}</Paragraph>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export { MonthlySavingsTable };
