const PRECISELY_CONTRACTING_TIME = 4.5;

const CONTRACTING_TIME_FACTOR = {
  simple: 0.25,
  standard: 0.5,
  complex: 1,
};

// DO NOT TOUCH - the keys much match the ids for each checkbox
const CONTRACTING_EFFICIENCY = {
  archiving: {
    max: 1,
    blank: -0.5,
    solutions: {
      shared_folder_categorized: -0.5,
      shared_folder_uncategorized: -0.25,
      individual_computers: 0,
      archiving_software: -0.75,
      no_solution: 0,
    },
  },
  templating: {
    max: 10,
    blank: -5,
    solutions: {
      templating_software: -7,
      templates_shared: -4,
      templates_individual: -3,
      third_party_drafting: -6,
      no_solution: 0,
    },
  },
  signing: {
    max: 6,
    blank: -2,
    solutions: {
      e_signing_software: -4.5,
      pdf_email: -2,
      physical_mail: 0,
    },
  },
  postSigning: {
    max: 1,
    blank: -0.5,
    solutions: {
      spreadsheet: -0.5,
      post_signing_software: -0.75,
      no_solution: 0,
    },
  },
};

const calculateContractTime = (
  currentSolution: { [key: string]: any[] },
  isInbound = false
) => {
  let totalTime = 0;

  Object.entries(currentSolution).forEach(([key, checkBoxGroup]) => {
    const efficiencySetting = CONTRACTING_EFFICIENCY[key];

    if (isInbound && !["postSigning", "archiving"].includes(key)) {
      return;
    }

    // Calculate Average efficiency
    let totalEfficiency = 0;
    let isLeftBlank = true;
    let numberOfSolutions = 0;

    checkBoxGroup.forEach((checkbox) => {
      if (checkbox.isChecked) {
        const efficiency = efficiencySetting.solutions[checkbox.id];

        totalEfficiency += efficiency;
        numberOfSolutions += 1;

        if (isLeftBlank) {
          isLeftBlank = false;
          return;
        }
      }
    });

    const averageEfficiency = isLeftBlank
      ? efficiencySetting.blank
      : totalEfficiency / numberOfSolutions;

    totalTime += efficiencySetting.max + averageEfficiency;
  });

  return totalTime;
};

const useContractingTimeCalculation = ({
  monthlyOutboundContracts,
  monthlyInboundContracts,
  percentOfSimple,
  percentOfStandard,
  percentOfComplex,
  currentSolution,
}: {
  monthlyOutboundContracts: number;
  monthlyInboundContracts: number;
  percentOfSimple: number;
  percentOfStandard: number;
  percentOfComplex: number;
  currentSolution: { [key: string]: any[] };
}) => {
  // OUTBOUND CONTRACTS

  // Calculate amount of contracts
  const monthlyOutboundSimpleContracts =
    monthlyOutboundContracts * (percentOfSimple / 100);
  const monthlyOutboundStandardContracts =
    monthlyOutboundContracts * (percentOfStandard / 100);
  const monthlyOutboundComplexContracts =
    monthlyOutboundContracts * (percentOfComplex / 100);

  const averageTimePerOutboundContractWithCurrentSolution =
    calculateContractTime(currentSolution);

  const monthlyHoursOnOutboundSimpleContracts =
    monthlyOutboundSimpleContracts *
    CONTRACTING_TIME_FACTOR.simple *
    averageTimePerOutboundContractWithCurrentSolution;

  const monthlyHoursOnOutboundStandardContracts =
    monthlyOutboundStandardContracts *
    CONTRACTING_TIME_FACTOR.standard *
    averageTimePerOutboundContractWithCurrentSolution;

  const monthlyHoursOnOutboundComplexContracts =
    monthlyOutboundComplexContracts *
    CONTRACTING_TIME_FACTOR.complex *
    averageTimePerOutboundContractWithCurrentSolution;

  const monthlyHoursOutboundContractingWithCurrentSolution =
    monthlyHoursOnOutboundSimpleContracts +
    monthlyHoursOnOutboundStandardContracts +
    monthlyHoursOnOutboundComplexContracts;

  // INBOUND
  const averageTimePerInboundContractWithCurrentSolution =
    calculateContractTime(currentSolution, true);

  const monthlyInboundSimpleContracts =
    monthlyInboundContracts * (percentOfSimple / 100);
  const monthlyInboundStandardContracts =
    monthlyInboundContracts * (percentOfStandard / 100);
  const monthlyInboundComplexContracts =
    monthlyInboundContracts * (percentOfComplex / 100);

  const monthlyHoursOnInboundSimpleContracts =
    monthlyInboundSimpleContracts *
    CONTRACTING_TIME_FACTOR.simple *
    averageTimePerInboundContractWithCurrentSolution;

  const monthlyHoursOnInboundStandardContracts =
    monthlyInboundStandardContracts *
    CONTRACTING_TIME_FACTOR.standard *
    averageTimePerInboundContractWithCurrentSolution;

  const monthlyHoursOnInboundComplexContracts =
    monthlyInboundComplexContracts *
    CONTRACTING_TIME_FACTOR.complex *
    averageTimePerInboundContractWithCurrentSolution;

  const monthlyHoursInboundContractingWithCurrentSolution =
    monthlyHoursOnInboundSimpleContracts +
    monthlyHoursOnInboundStandardContracts +
    monthlyHoursOnInboundComplexContracts;

  // Calculate with Precisely
  const monthlyHoursOnOutboundSimpleContractsWithPrecisely =
    monthlyOutboundSimpleContracts *
    CONTRACTING_TIME_FACTOR.simple *
    PRECISELY_CONTRACTING_TIME;

  const monthlyHoursOnOutboundStandardContractsWithPrecisely =
    monthlyOutboundStandardContracts *
    CONTRACTING_TIME_FACTOR.standard *
    PRECISELY_CONTRACTING_TIME;

  const monthlyHoursOnOutboundComplexContractsWithPrecisely =
    monthlyOutboundComplexContracts *
    CONTRACTING_TIME_FACTOR.complex *
    PRECISELY_CONTRACTING_TIME;

  const monthlyHoursOutboundContractingWithPrecisely =
    monthlyHoursOnOutboundSimpleContractsWithPrecisely +
    monthlyHoursOnOutboundStandardContractsWithPrecisely +
    monthlyHoursOnOutboundComplexContractsWithPrecisely;

  // Out of the 4.5 hours we estimate a contract to take in Precisely, 0.5 hours of those are estimated to be for Archiving and Post-signing.
  const averageTimePerInboundContractingWithPrecisely = 0.5;

  const averageTimePerOutboundContractingWithPrecisely =
    PRECISELY_CONTRACTING_TIME *
      CONTRACTING_TIME_FACTOR.simple *
      (percentOfSimple / 100) +
    PRECISELY_CONTRACTING_TIME *
      CONTRACTING_TIME_FACTOR.standard *
      (percentOfStandard / 100) +
    PRECISELY_CONTRACTING_TIME *
      CONTRACTING_TIME_FACTOR.complex *
      (percentOfComplex / 100);

  const monthlyHoursOnInboundSimpleContractsWithPrecisely =
    monthlyInboundSimpleContracts *
    CONTRACTING_TIME_FACTOR.simple *
    averageTimePerInboundContractingWithPrecisely;

  const monthlyHoursOInboundStandardContractsWithPrecisely =
    monthlyInboundStandardContracts *
    CONTRACTING_TIME_FACTOR.standard *
    averageTimePerInboundContractingWithPrecisely;

  const monthlyHoursOnInboundComplexContractsWithPrecisely =
    monthlyInboundComplexContracts *
    CONTRACTING_TIME_FACTOR.complex *
    averageTimePerInboundContractingWithPrecisely;

  const monthlyHoursInboundContractingWithPrecisely =
    monthlyHoursOnInboundSimpleContractsWithPrecisely +
    monthlyHoursOInboundStandardContractsWithPrecisely +
    monthlyHoursOnInboundComplexContractsWithPrecisely;

  const monthlyHoursContractingWithCurrentSolution =
    monthlyHoursOutboundContractingWithCurrentSolution +
    monthlyHoursInboundContractingWithCurrentSolution;

  const monthlyHoursContractingWithPrecisely =
    monthlyHoursOutboundContractingWithPrecisely +
    monthlyHoursInboundContractingWithPrecisely;

  return {
    monthlyHoursContractingWithCurrentSolution,
    monthlyHoursContractingWithPrecisely,
    averageTimePerOutboundContractWithCurrentSolution,
    averageTimePerOutboundContractingWithPrecisely,
  };
};

export { useContractingTimeCalculation };
