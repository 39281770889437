import { useFormatCurrency } from "../../hooks/useFormatCurrency";
import { InfoGraphic } from "../InfoGraphic";
import { Question } from "../layout/Question";
import { Section } from "../layout/Section";
import { SplitSections } from "../layout/SplitSections";
import { MonthlyCostTable } from "../MonthlyCostTable";
import { MonthlySavingsTable } from "../MonthlySavingsTable";
import { MonthlyTimeSavingsTable } from "../MonthlyTimeSavingsTable";
import { RoiTable } from "../RoiTable";

const InternalRoiSection = ({ roiCalculation }) => {
  const { formatCurrency } = useFormatCurrency();

  return (
    <Section header="Precisely benefits and Return on Investment">
      <SplitSections
        sectionA={
          <>
            <Question header="Suggested monthly Precisely Package">
              <MonthlyCostTable {...roiCalculation.monthly.costs} />
            </Question>
            <Question header="Estimated monthly savings">
              <MonthlySavingsTable {...roiCalculation.monthly.savings} />
            </Question>
            <Question header="">
              <MonthlyTimeSavingsTable
                hoursSaved={roiCalculation.monthly.hoursSaved}
                contractLeadTimeSavings={
                  roiCalculation.monthly.contractLeadTimeSavings
                }
              />
            </Question>
          </>
        }
        sectionB={
          <>
            <InfoGraphic
              amount={roiCalculation.breakEvenMonth}
              unit="Month"
              heading="Break even month"
              description={`You are estimated to reach break even in month ${roiCalculation.breakEvenMonth} with Precisely.`}
            />
            <InfoGraphic
              amount={roiCalculation.hoursSavedPerYear}
              unit="Hours"
              heading="Hours saved per year"
              description={`You will save ${roiCalculation.hoursSavedPerYear} hours per year by using Precisely.`}
            />
            <InfoGraphic
              amount={roiCalculation.totalMoneySaved}
              unit="USD"
              heading="Money saved after 3 years"
              description={`After 3 years, your organization has saved a total of ${formatCurrency(
                roiCalculation.totalMoneySaved
              )}.`}
            />
            <InfoGraphic
              amount={roiCalculation.contractLeadTimeDaysSavings}
              unit="Days"
              heading="Shorter contract lead time"
              description={`You contracting lead time will be ${roiCalculation.contractLeadTimeDaysSavings} days shorter per year.`}
            />
          </>
        }
      />
      <Question header="Return on Investment">
        <RoiTable roiYears={roiCalculation.years} />
      </Question>
    </Section>
  );
};

export { InternalRoiSection };
