/** @jsxImportSource @emotion/react */
import { useFormatCurrency } from "../hooks/useFormatCurrency";
import { useTheme } from "../hooks/useTheme";
import { RoiTableRow } from "../types/RoiTableRow";
import { Paragraph } from "./text/Paragraph";

const TableRow = ({
  roiYears,
  property,
  label,
  isBold = false,
  isPercentage = false,
}: {
  roiYears: { [year: number]: RoiTableRow };
  property: string;
  label: string;
  isBold?: boolean;
  isPercentage?: boolean;
}) => {
  const { formatCurrency } = useFormatCurrency();

  return (
    <tr key={property}>
      <td>
        <Paragraph isBold={isBold}>{label}</Paragraph>
      </td>
      {Object.entries(roiYears).map(([year, roiYear]) => (
        <td key={`${property}-year-${year}`} css={{ textAlign: "right" }}>
          <Paragraph isBold={isBold}>
            {isPercentage
              ? `${roiYear[property]}%`
              : formatCurrency(roiYear[property])}
          </Paragraph>
        </td>
      ))}
    </tr>
  );
};

const RoiTable = ({ roiYears }: RoiTableProps) => {
  const { spacing } = useTheme();

  return (
    <div>
      <table css={{ width: "100%", " td": { padding: `${spacing.S} 0` } }}>
        <thead>
          <tr css={{ borderBottom: "1px solid #333" }}>
            <td></td>
            {Object.keys(roiYears).map((year) => (
              <td key={`heading-year-${year}`} css={{ textAlign: "right" }}>
                <Paragraph isBold>Year {year}</Paragraph>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          <TableRow
            roiYears={roiYears}
            property="userLicenseCosts"
            label="User license costs"
          />
          <TableRow
            roiYears={roiYears}
            property="addOnLicenseCosts"
            label="Add-ons and integration costs"
          />
          <TableRow
            roiYears={roiYears}
            property="serviceCosts"
            label="Service costs"
          />
          <TableRow
            roiYears={roiYears}
            property="internalCosts"
            label="Internal Costs"
          />
          <TableRow
            roiYears={roiYears}
            property="totalCosts"
            label="Total costs"
            isBold
          />
          <tr>
            <td></td>
          </tr>
          <TableRow
            roiYears={roiYears}
            property="contractingSavings"
            label="Contracting savings"
          />
          <TableRow
            roiYears={roiYears}
            property="toolReplacementSavings"
            label="Tool replacement savings"
          />
          <TableRow
            roiYears={roiYears}
            property="totalSavings"
            label="Total savings"
            isBold
          />
          <tr>
            <td></td>
          </tr>
          <TableRow
            roiYears={roiYears}
            property="netResult"
            label="Net result"
          />
          <TableRow
            roiYears={roiYears}
            property="cumulativeNetResult"
            label="Cumulative net result"
            isBold
          />
          <tr>
            <td></td>
          </tr>
          <TableRow
            roiYears={roiYears}
            property="returnOnInvestment"
            label="Return on investment"
            isPercentage
          />
          <TableRow
            roiYears={roiYears}
            property="cumulativeReturnOnInvestment"
            label="Cumulative return on investment"
            isPercentage
            isBold
          />
        </tbody>
      </table>
    </div>
  );
};

type RoiTableProps = {
  roiYears: { [year: number]: RoiTableRow };
};

export { RoiTable };
