/** @jsxImportSource @emotion/react */
import { useMediaQuery } from "../../hooks/useMediaQuery";

const GUTTER = 70;

const SplitSections = ({ sectionA, sectionB }) => {
  const { windowLessThan } = useMediaQuery();

  const isMobileLayout = windowLessThan.M;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: isMobileLayout ? "column" : "row",
      }}
    >
      <div
        css={{
          flex: 1,
          paddingRight: isMobileLayout ? undefined : `${GUTTER}px`,
          boxSizing: "border-box",
        }}
      >
        {sectionA}
      </div>
      <div
        css={{
          flex: 1,
          boxSizing: "border-box",
        }}
      >
        {sectionB}
      </div>
    </div>
  );
};

export { SplitSections };
