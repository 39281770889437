// In segmented checkbox group format
export const currentSolution = {
  archiving: [
    {
      id: "shared_folder_categorized",
      isChecked: false,
      name: "Contract categorized in shared folder",
    },
    {
      id: "shared_folder_uncategorized",
      isChecked: false,
      name: "Contracts uncategorized in shared folder",
    },
    {
      id: "individual_computers",
      isChecked: false,
      name: "Contracts on individual computers",
    },
    {
      id: "archiving_software",
      isChecked: false,
      name: "Contract archive software",
    },
    {
      id: "no_solution",
      isChecked: false,
      name: "No archiving solution",
    },
  ],
  templating: [
    {
      id: "templating_software",
      isChecked: false,
      name: "Drafting/Template software",
    },
    {
      id: "templates_shared",
      isChecked: false,
      name: "Common templates on shared drive",
    },
    {
      id: "templates_individual",
      isChecked: false,
      name: "Templates on individual computers",
    },
    {
      id: "third_party_drafting",
      isChecked: false,
      name: "Third party drafting",
    },
    {
      id: "no_solution",
      isChecked: false,
      name: "No drafting/template software",
    },
  ],
  signing: [
    {
      id: "e_signing_software",
      isChecked: false,
      name: "E-signing tool",
    },
    {
      id: "pdf_email",
      isChecked: false,
      name: "PDF and email",
    },
    {
      id: "physical_mail",
      isChecked: false,
      name: "Physical mail",
    },
  ],
  postSigning: [
    {
      id: "spreadsheet",
      isChecked: false,
      name: "Spreadsheet with important dates",
    },
    {
      id: "post_signing_software",
      isChecked: false,
      name: "Post-signing software",
    },
    {
      id: "no_solution",
      isChecked: false,
      name: "No post-signing software",
    },
  ],
  integrations: [
    {
      id: "hubspot",
      isChecked: false,
      name: "HubSpot",
    },
    {
      id: "salesforce",
      isChecked: false,
      name: "Salesforce",
    },
    {
      id: "slack",
      isChecked: false,
      name: "Slack",
    },
    {
      id: "teams",
      isChecked: false,
      name: "Teams",
    },
    {
      id: "hr",
      isChecked: false,
      name: "HR-tools",
    },
    {
      id: "zapier",
      isChecked: false,
      name: "Zapier",
    },
  ],
};
