/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { Paragraph } from "./text/Paragraph";

const MonthlyTimeSavingsTable = ({
  hoursSaved,
  contractLeadTimeSavings,
}: {
  hoursSaved: number;
  contractLeadTimeSavings: number;
}) => {
  const { spacing } = useTheme();

  return (
    <table css={{ width: "100%" }}>
      <tbody>
        <tr>
          <td css={{ padding: `${spacing.S} 0` }}>
            <Paragraph>Monthly hours made available</Paragraph>
          </td>
          <td css={{ padding: `${spacing.S} 0`, textAlign: "right" }}>
            <Paragraph>{hoursSaved} Hours</Paragraph>
          </td>
        </tr>
        <tr>
          <td css={{ padding: `${spacing.S} 0` }}>
            <Paragraph>Faster contract lead times</Paragraph>
          </td>
          <td css={{ padding: `${spacing.S} 0`, textAlign: "right" }}>
            <Paragraph>{contractLeadTimeSavings} Days</Paragraph>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export { MonthlyTimeSavingsTable };
