import { useTheme } from "@emotion/react";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { RangeInput } from "../input/RangeInput";
import { SliderInput } from "../input/SliderInput";
import { Question } from "../layout/Question";
import { Section } from "../layout/Section";

const ContractsSection = ({
  monthlyContracts,
  setMonthlyContracts,
  outboundContractsPercentage,
  setOutboundContractsPercentage,
  contractMix,
  setContractMix,
}) => {
  const { color } = useTheme();
  const { windowLessThan } = useMediaQuery();

  const isMobileLayout = windowLessThan.M;

  return (
    <Section header="Current contract types and volumes" subHeader="Step 1">
      <Question
        header="How many contracts does your organization handle per month?"
        description="Consider all types of contracts throughout your organization for this input, both ones you generate and ones you receive from third parties. Include all contracts you create, regardless of whether they are drafted from scratch or completely templated. We have provided a number of reference points for typical organizations of varying size. The averages values are based on current Precisely users and research from Association of Corporate Counsel (ACC)."
      >
        <SliderInput
          value={monthlyContracts}
          onChange={setMonthlyContracts}
          unit="contracts/month"
          minValue={5}
          maxValue={300}
          marks={{
            50: {
              label: "Scale-up",
            },
            100: {
              label: isMobileLayout ? "Small corp." : "Small corporate",
            },
            180: {
              label: isMobileLayout ? "Avg. corp." : "Average corporate",
            },
            250: {
              label: "Enterprise",
            },
          }}
        />
      </Question>

      <Question
        header="How many of the contracts handled originate from your organization?"
        description="As this calculator considers both contracts originating from your organization and from third parties, it is important to know the proportion between the two types of contracts. Include all contracts you create, regardless of whether they are created from scratch or you just change a few unique elements in a template."
      >
        <SliderInput
          value={outboundContractsPercentage}
          onChange={setOutboundContractsPercentage}
          unit="%"
          maxValue={100}
        />
      </Question>

      <Question
        header="What type of contracts does your organization handle?"
        description="Not all contracts are created equal and contract complexity and standardization have a significant impact on resources needed for creation, negotiation, approval, and oversight. Consider both contracts originating from your organization and ones you receive from third parties. Select between the three contract categories. As a reference point, we have provided descriptions of the typical contracts in each category."
      >
        <RangeInput
          value={contractMix}
          onChange={setContractMix}
          rangeStyles={[
            {
              label: "Simple",
              description: (
                <span>
                  Using a standard form of contract with little negotiation and
                  requiring limited performance oversight
                  <br />
                  Typical contracts: NDA, standardized/recurring sales, basic
                  employment
                </span>
              ),
              color: color.primary.main,
            },
            {
              label: "Standard",
              description: (
                <span>
                  Requiring significant review and/or negotiation and continued
                  oversight of performance
                  <br />
                  Typical contracts: Sales, leases, data, procurement
                </span>
              ),
              color: color.green.main,
            },
            {
              label: "Complex",
              description: (
                <span>
                  Represents substantial business risk, requiring skilled
                  negotiators and dedicated oversight of performance
                  <br />
                  Typical contracts: Partnerships, manufacturing, regulatory,
                  corporate transactions
                </span>
              ),
              color: color.orange.main,
            },
          ]}
        />
      </Question>
    </Section>
  );
};

export { ContractsSection };
