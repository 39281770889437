import { useState } from "react";
import { addOnSuggestions } from "../data/addOnSuggestions";
import { currentSolution } from "../data/currentSolution";
import { licenseSuggestions } from "../data/licenseSuggestions";
import { serviceSuggestions } from "../data/serviceSuggestions";
import { useCheckboxCostTableSync } from "./useCheckboxCostTableSync";
import { useCheckboxGroup } from "./useCheckboxGroup";
import { useCostTable } from "./useCostTable";
import { useSliderCostTableRowSync } from "./useSliderCostTableRowSync";

const useRoiCalculatorInputs = () => {
  // Contracts
  const [monthlyContracts, setMonthlyContracts] = useState(140);
  const [outboundContractsPercentage, setOutboundContractsPercentage] =
    useState(50);

  const [contractMix, setContractMix] = useState([60, 30, 10]);

  const [contractorRate, setContractorRate] = useState(90);

  // Team slider
  const [adminSlider, setAdminSlider] = useState(5);
  const [managerSlider, setManagerSlider] = useState(15);
  const [memberSlider, setMemberSlider] = useState(15);
  const [viewerSlider, setViewerSlider] = useState(10);

  // Current solution
  const archivingCheckboxGroup = useCheckboxGroup(currentSolution.archiving);
  const templatingCheckboxGroup = useCheckboxGroup(currentSolution.templating);
  const signingCheckboxGroup = useCheckboxGroup(currentSolution.signing);
  const postSigningCheckboxGroup = useCheckboxGroup(
    currentSolution.postSigning
  );
  const integrationsCheckboxGroup = useCheckboxGroup(
    currentSolution.integrations
  );

  // Precisely costs
  const userLicensesCostTable = useCostTable(licenseSuggestions);
  const addOnsCostTable = useCostTable(
    addOnSuggestions.filter(({ id }) => ["support_advanced"].includes(id))
  );
  const servicesCostTable = useCostTable(
    serviceSuggestions.filter(({ id }) =>
      ["onboarding_basic", "workflow_automation_basic"].includes(id)
    )
  );

  // SYNCING DATA

  // Users - setting licenses based on sliders
  useSliderCostTableRowSync(
    adminSlider,
    userLicensesCostTable,
    "admin",
    "amount"
  );
  useSliderCostTableRowSync(
    managerSlider,
    userLicensesCostTable,
    "manager",
    "amount"
  );
  useSliderCostTableRowSync(
    memberSlider,
    userLicensesCostTable,
    "member",
    "amount"
  );
  useSliderCostTableRowSync(
    viewerSlider,
    userLicensesCostTable,
    "viewer",
    "amount"
  );

  // Addons and Integrations - adding integrations based on current solution
  useCheckboxCostTableSync(
    integrationsCheckboxGroup,
    addOnsCostTable,
    addOnSuggestions
  );
  useCheckboxCostTableSync(
    integrationsCheckboxGroup,
    servicesCostTable,
    serviceSuggestions
  );

  return {
    monthlyContracts,
    setMonthlyContracts,
    outboundContractsPercentage,
    setOutboundContractsPercentage,
    contractMix,
    setContractMix,
    contractorRate,
    setContractorRate,
    adminSlider,
    setAdminSlider,
    managerSlider,
    setManagerSlider,
    memberSlider,
    setMemberSlider,
    viewerSlider,
    setViewerSlider,
    archivingCheckboxGroup,
    templatingCheckboxGroup,
    signingCheckboxGroup,
    postSigningCheckboxGroup,
    integrationsCheckboxGroup,
    userLicensesCostTable,
    addOnsCostTable,
    servicesCostTable,
  };
};

export { useRoiCalculatorInputs };
