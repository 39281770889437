import styled from "@emotion/styled";

export const Header4 = styled.h4(
  {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "20px",
  },
  ({ theme: { color } }) => ({
    color: color.gray.dark,
  })
);
