import { useEffect } from "react";
import { CostTableRow } from "../types/CostTableRow";
import { useCheckboxGroup } from "./useCheckboxGroup";
import { useCostTable } from "./useCostTable";

const useCheckboxCostTableSync = (
  checkboxGroup: ReturnType<typeof useCheckboxGroup>,
  costTable: ReturnType<typeof useCostTable>,
  costTableSuggestions: CostTableRow[]
) => {
  const { setTableRows } = costTable;
  const { state: checkboxGroupState } = checkboxGroup;

  useEffect(() => {
    // Check if there's an integration added already
    setTableRows((tableRows) => {
      let updatedTableRows = [...tableRows];

      checkboxGroupState.forEach((checkboxState) => {
        const existingTableRowSuggestion = updatedTableRows.find(({ id }) =>
          id.includes(checkboxState.id)
        );

        if (checkboxState.isChecked) {
          // If the integration is checked and there's no addon for that integration, add it automatically.
          if (!existingTableRowSuggestion) {
            // Try to find a suitable addon based on id
            const addOn = costTableSuggestions.find(({ id }) => {
              return id.includes(checkboxState.id);
            });
            if (addOn) {
              updatedTableRows.push(addOn);
            }
          }
        } else {
          // If the integration is not checked and a addon has been previously added automatically, remove it
          if (
            existingTableRowSuggestion &&
            !existingTableRowSuggestion.isAddedManually
          ) {
            updatedTableRows = updatedTableRows.filter(
              ({ id }) => !id.includes(existingTableRowSuggestion.id)
            );
          }
        }
      });
      return updatedTableRows;
    });
  }, [checkboxGroupState, setTableRows, costTableSuggestions]);
};

export { useCheckboxCostTableSync };
