/** @jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";
import { useFormatCurrency } from "../hooks/useFormatCurrency";
import { Paragraph } from "./text/Paragraph";

const MonthlyCostTable = ({
  userLicenses,
  addOns,
  totalCost,
}: {
  userLicenses: {
    amount: number;
    totalCost: number;
  };
  addOns: {
    amount: number;
    totalCost: number;
  };
  totalCost: number;
}) => {
  const { spacing } = useTheme();
  const { formatCurrency } = useFormatCurrency();

  return (
    <table css={{ width: "100%", marginTop: spacing.M }}>
      <tbody>
        <tr>
          <td css={{ padding: `${spacing.S} 0` }}>
            <Paragraph>User licenses</Paragraph>
          </td>
          <td css={{ padding: spacing.S, textAlign: "right" }}>
            <Paragraph>{userLicenses.amount}</Paragraph>
          </td>
          <td css={{ padding: spacing.S, textAlign: "right" }}>
            <Paragraph>Total cost</Paragraph>
          </td>
          <td css={{ padding: `${spacing.S} 0`, textAlign: "right" }}>
            <Paragraph>{formatCurrency(userLicenses.totalCost)}</Paragraph>
          </td>
        </tr>
        <tr>
          <td css={{ padding: `${spacing.S} 0` }}>
            <Paragraph>Add-ons and integrations</Paragraph>
          </td>
          <td css={{ padding: spacing.S, textAlign: "right" }}>
            <Paragraph>{addOns.amount}</Paragraph>
          </td>
          <td css={{ padding: spacing.S, textAlign: "right" }}>
            <Paragraph>Total cost</Paragraph>
          </td>
          <td css={{ padding: `${spacing.S} 0`, textAlign: "right" }}>
            <Paragraph>{formatCurrency(addOns.totalCost)}</Paragraph>
          </td>
        </tr>

        <tr css={{ borderTop: "1px solid #5D6F7E" }}>
          <td css={{ padding: `${spacing.S} 0` }}>
            <Paragraph isBold>Total monthly costs</Paragraph>
          </td>
          <td></td>
          <td></td>
          <td css={{ padding: `${spacing.S} 0`, textAlign: "right" }}>
            <Paragraph isBold>{formatCurrency(totalCost)}</Paragraph>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export { MonthlyCostTable };
