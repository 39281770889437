/** @jsxImportSource @emotion/react */
import { ReactNode } from "react";
import { useTheme } from "../../hooks/useTheme";
import { Header1 } from "../text/Header1";
import { Paragraph } from "../text/Paragraph";

const Section = ({
  header,
  subHeader,
  description,
  children,
}: SectionProps) => {
  const { spacing, color } = useTheme();

  return (
    <section css={{ marginBottom: "10rem" }}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {subHeader && (
          <Paragraph
            isBold
            css={{
              textTransform: "uppercase",
              letterSpacing: "0.1rem",
              color: color.gray.medium,
              opacity: 0.6,
              marginBottom: spacing.S,
            }}
          >
            {subHeader}
          </Paragraph>
        )}
        <Header1
          css={{
            display: "flex",
            alignItems: "center",
            marginBottom: spacing.L,
            maxWidth: "600px",
            textAlign: "center",
          }}
        >
          {header}
        </Header1>
        <div
          css={{ width: "80px", height: "4px", background: color.primary.main }}
        />
      </div>
      <div css={{ marginTop: "7rem" }}>
        {description && <Paragraph>{description}</Paragraph>}
        <div css={{ marginTop: spacing["4XL"] }}>{children}</div>
      </div>
    </section>
  );
};

type SectionProps = {
  header: string;
  subHeader?: string;
  description?: ReactNode;
  children: ReactNode;
};

export { Section };
