import { CostTableRow } from "../types/CostTableRow";

export const addOnSuggestions: CostTableRow[] = [
  {
    id: "support_basic",
    name: "Support - Basic",
    listPrice: 0,
    discount: 0,
    amount: 1,
    isAddedManually: false,
  },
  {
    id: "support_advanced",
    name: "Support - Advanced",
    listPrice: 199,
    discount: 0,
    amount: 1,
    isAddedManually: false,
  },
  {
    id: "support_premium",
    name: "Support - Premium",
    listPrice: 395,
    discount: 0,
    amount: 1,
    isAddedManually: false,
  },
  {
    id: "hubspot",
    name: "HubSpot",
    listPrice: 349,
    discount: 0,
    amount: 1,
    isAddedManually: false,
  },
  {
    id: "salesforce",
    name: "SalesForce",
    listPrice: 349,
    discount: 0,
    amount: 1,
    isAddedManually: false,
  },
  {
    id: "api",
    name: "REST API",
    listPrice: 349,
    discount: 0,
    amount: 1,
    isAddedManually: false,
  },
  {
    id: "zapier",
    name: "Zapier",
    listPrice: 99,
    discount: 0,
    amount: 1,
    isAddedManually: false,
  },
  {
    id: "on_prem",
    name: "On-Premise",
    listPrice: 799,
    discount: 0,
    amount: 1,
    isAddedManually: false,
  },
  {
    id: "military_security",
    name: "Military-Grade Security",
    listPrice: 2999,
    discount: 0,
    amount: 1,
    isAddedManually: false,
  },
  {
    id: "sso",
    name: "Single Sign-On (SSO)",
    listPrice: 55,
    discount: 0,
    amount: 1,
    isAddedManually: false,
  },
  {
    id: "ad",
    name: "Active Directory (AD)",
    listPrice: 55,
    discount: 0,
    amount: 1,
    isAddedManually: false,
  },
];
