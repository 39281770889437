import styled from "@emotion/styled";

const Paragraph = styled("p")<ParagraphProps>(
  {
    fontFamily: "Helvetica Neue Pro Roman",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "1.5rem",
    lineHeight: "22px",
    textRendering: "optimizeLegibility",
    WebkitFontSmoothing: "antialiased",
    color: "rgba(21,21,21,.7)",

    " i": {
      fontStyle: "italic",
    },
  },
  ({ theme: { color }, isBold }) => ({
    fontWeight: isBold ? "bold" : undefined,
    color: color.gray.dark,

    " a": {
      transition: "all 0.3s",
      textDecoration: "none",
      color: "inherit",
      borderBottom: `2px solid ${color.primary.main}`,

      "&:hover": {
        color: color.primary.main,
      },
    },
  })
);

type ParagraphProps = {
  isBold?: boolean;
};

export { Paragraph };
