import { SliderInput } from "../input/SliderInput";
import { Question } from "../layout/Question";
import { Section } from "../layout/Section";
import { SplitSections } from "../layout/SplitSections";

const TeamSection = ({
  adminSlider,
  setAdminSlider,
  managerSlider,
  setManagerSlider,
  memberSlider,
  setMemberSlider,
  viewerSlider,
  setViewerSlider,
  contractorRate,
  setContractorRate,
}) => {
  return (
    <Section
      header="Current contracting team and resources"
      subHeader="Step 3"
      description="Creating, managing, overseeing, and using contracts is a team effort and not everyone does everything. In the section below, assign the number of people in their most appropriate role. These input parameters have a significant impact on estimating the internal costs as you get started with a CLM. We have also provided some reference descriptions for the various roles."
    >
      <SplitSections
        sectionA={
          <Question
            header="Managing access and approvals"
            description="Typically your senior legal team or main administrators, who manage contract approvals and access rights on behalf of the organization."
          >
            <SliderInput
              value={adminSlider}
              onChange={setAdminSlider}
              unit="people"
              minValue={1}
              maxValue={50}
            />
          </Question>
        }
        sectionB={
          <Question
            header="Creating templates and approving"
            description="Typically your legal team or senior leadership in other functions that manage and create templates and approve contracts and deals created by their team."
          >
            <SliderInput
              value={managerSlider}
              onChange={setManagerSlider}
              unit="people"
              minValue={0}
              maxValue={50}
            />
          </Question>
        }
      />

      <SplitSections
        sectionA={
          <Question
            header="Using templates and chasing signatures"
            description="Typically external-facing people (eg. sales or HR) creating contracts from existing templates, created by others, and sending them to external parties for signature."
          >
            <SliderInput
              value={memberSlider}
              onChange={setMemberSlider}
              unit="people"
              minValue={0}
              maxValue={50}
            />
          </Question>
        }
        sectionB={
          <Question
            header="Viewing and tracking"
            description="People that can access and track documents shared with them, such as senior stakeholders or paralegals."
          >
            <SliderInput
              value={viewerSlider}
              onChange={setViewerSlider}
              unit="people"
              minValue={0}
              maxValue={50}
            />
          </Question>
        }
      />

      <Question
        header="Average hourly rate"
        description="In order to assign a value to contracting processes, you must provide the average hourly cost of a person handling contracts in your company. Naturally, this varies greatly between organizations and whether the person handling the contracts is a senior or junior employee as well as whether they are legal professionals or not. As a reference, averages from Associate of Corporate Counsel suggest a range from $112 to $131 for lawyers, and $65 for non-lawyers. If applicable to your organization, use your internal billing costs or third party rates."
      >
        <SliderInput
          value={contractorRate}
          onChange={setContractorRate}
          unit="USD"
          minValue={20}
          maxValue={200}
        />
      </Question>
    </Section>
  );
};

export { TeamSection };
