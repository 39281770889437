import { RoiTableRow } from "../types/RoiTableRow";
import { useContractingTimeCalculation } from "./useContractingTimeCalculation";
import { useToolReplacementCalculation } from "./useToolReplacementCalculation";

// Constants
const YEARS = 3;
const INTERNAL_ONBOARDING_HOURS = {
  admin: 40,
  manager: 30,
  member: 20,
  viewer: 4,
};

const useRoiCalculation = ({
  monthlyUserLicenseCost,
  monthlyAddOnLicenses,
  monthlyAddOnLicenseCost,
  outboundContractsPercentage,
  serviceCost,
  userLicenses: { admin, manager, member, viewer },
  internalUserRate,
  currentSolution,
  contracts: {
    monthlyContracts,
    percentOfSimple,
    percentOfStandard,
    percentOfComplex,
  },
}: {
  monthlyUserLicenseCost: number;
  monthlyAddOnLicenses: number;
  monthlyAddOnLicenseCost: number;
  outboundContractsPercentage: number;

  serviceCost: number;
  userLicenses: {
    admin: number;
    manager: number;
    member: number;
    viewer: number;
  };
  internalUserRate: number;
  currentSolution: {
    archiving: any;
    templating: any;
    signing: any;
    postSigning: any;
  };
  contracts: {
    monthlyContracts: number;
    percentOfSimple: number;
    percentOfStandard: number;
    percentOfComplex: number;
  };
}): {
  monthly: {
    costs: {
      userLicenses: {
        amount: number;
        totalCost: number;
      };
      addOns: {
        amount: number;
        totalCost: number;
      };
      totalCost: number;
    };
    savings: {
      contractingSavings: number;
      toolReplacementSavings: number;
      netSavings: number;
      totalSavings: number;
    };
    hoursSaved: number;
    contractLeadTimeSavings: number;
  };
  averageTimePerOutboundContractWithCurrentSolution: number;
  averageTimePerOutboundContractingWithPrecisely: number;
  breakEvenMonth: number;
  hoursSavedPerYear: number;
  totalMoneySaved: number;
  contractLeadTimeDaysSavings: number;
  years: { [year: number]: RoiTableRow };
} => {
  ///////////////////////////////////////////
  ////////////////////// MONTHLY CALCULATIONS
  ///////////////////////////////////////////

  // Monthly costs
  const monthlyUserLicenses = admin + manager + member + viewer;
  const monthlyTotalCost = monthlyUserLicenseCost + monthlyAddOnLicenseCost;

  // Contracts
  const monthlyOutboundContracts =
    monthlyContracts * (outboundContractsPercentage / 100);
  const monthlyInboundContracts =
    monthlyContracts * ((100 - outboundContractsPercentage) / 100);

  // Monthly savings
  const {
    averageTimePerOutboundContractWithCurrentSolution,
    averageTimePerOutboundContractingWithPrecisely,
    monthlyHoursContractingWithCurrentSolution,
    monthlyHoursContractingWithPrecisely,
  } = useContractingTimeCalculation({
    monthlyOutboundContracts,
    monthlyInboundContracts,
    percentOfSimple,
    percentOfStandard,
    percentOfComplex,
    currentSolution,
  });

  const monthlyHoursSaved =
    monthlyHoursContractingWithCurrentSolution -
    monthlyHoursContractingWithPrecisely;

  const { monthlyToolReplacementSavings } = useToolReplacementCalculation({
    currentSolution,
  });

  const monthlyContractingSavings = monthlyHoursSaved * internalUserRate;
  const monthlyTotalSavings =
    monthlyContractingSavings + monthlyToolReplacementSavings;

  const monthlyNetSavings = monthlyTotalSavings - monthlyTotalCost;

  //////////////////////////////////////////
  ////////////////////// YEARLY CALCULATIONS
  //////////////////////////////////////////

  const years: { [year: number]: RoiTableRow } = {};

  let cumulativeNetResult = 0;
  let cumulativeTotalCost = 0;

  for (let i = 0; i < YEARS; i++) {
    const isFirstYear = i === 0;

    // Costs
    const yearlyUserLicenseCost = monthlyUserLicenseCost * 12;
    const yearlyAddOnLicenseCost = monthlyAddOnLicenseCost * 12;
    const yearlyServiceCost = isFirstYear ? serviceCost : 0;

    const yearlyInternalHours = isFirstYear
      ? INTERNAL_ONBOARDING_HOURS.admin * admin +
        INTERNAL_ONBOARDING_HOURS.manager * manager +
        INTERNAL_ONBOARDING_HOURS.member * member +
        INTERNAL_ONBOARDING_HOURS.viewer * viewer
      : 0;

    const yearlyInternalCost = isFirstYear
      ? yearlyInternalHours * internalUserRate
      : 0;

    const yearlyTotalCost =
      yearlyUserLicenseCost +
      yearlyAddOnLicenseCost +
      yearlyServiceCost +
      yearlyInternalCost;

    cumulativeTotalCost += yearlyTotalCost;

    // Savings
    const yearlyHoursSaved = monthlyHoursSaved * 12;
    const yearlyContractingSavings = yearlyHoursSaved * internalUserRate;

    const yearlyToolReplacementSavings = monthlyToolReplacementSavings * 12;

    const yearlyTotalSavings =
      yearlyContractingSavings + yearlyToolReplacementSavings;

    // Net result
    const yearlyNetResult = yearlyTotalSavings - yearlyTotalCost;

    cumulativeNetResult += yearlyNetResult;

    const yearlyCumulativeNetResult = cumulativeNetResult;

    // Return on investment
    const yearlyReturnOnInvestment = Math.round(
      (yearlyNetResult / yearlyTotalCost) * 100
    );

    const yearlyCumulativeReturnOnInvestment = Math.round(
      (cumulativeNetResult / cumulativeTotalCost) * 100
    );

    const year = {
      userLicenseCosts: yearlyUserLicenseCost,
      addOnLicenseCosts: yearlyAddOnLicenseCost,
      serviceCosts: yearlyServiceCost,
      internalCosts: yearlyInternalCost,
      totalCosts: yearlyTotalCost,

      hoursSaved: yearlyHoursSaved,
      contractingSavings: yearlyContractingSavings,
      toolReplacementSavings: yearlyToolReplacementSavings,
      totalSavings: yearlyTotalSavings,

      netResult: yearlyNetResult,
      cumulativeNetResult: yearlyCumulativeNetResult,

      returnOnInvestment: yearlyReturnOnInvestment,
      cumulativeReturnOnInvestment: yearlyCumulativeReturnOnInvestment,
    };

    years[i + 1] = year;
  }

  /////////////////////////////////////////////////
  ////////////////////// BULLET POINTS CALCULATIONS
  /////////////////////////////////////////////////

  // Break even month
  const yearValues = Object.values(years);
  const firstYear = yearValues[0];

  const breakEvenMonth = Math.ceil(
    (firstYear.internalCosts + firstYear.serviceCosts) / monthlyNetSavings
  );

  const monthlyContractLeadTimeSavings = Math.round(
    (monthlyOutboundContracts * (percentOfSimple / 100) * 35 +
      monthlyOutboundContracts * (percentOfStandard / 100) * 66.5 +
      monthlyOutboundContracts * (percentOfComplex / 100) * 154) *
      0.5
  );

  const contractLeadTimeDaysSavings = Math.round(
    (35 * (percentOfSimple / 100) +
      66.5 * (percentOfStandard / 100) +
      154 * (percentOfComplex / 100)) *
      ((averageTimePerOutboundContractWithCurrentSolution -
        averageTimePerOutboundContractingWithPrecisely) /
        averageTimePerOutboundContractWithCurrentSolution) *
      0.5
  );

  // Calculate hours saved
  const hoursSavedPerYear = Math.round(firstYear.hoursSaved);

  // Money saved after three years
  const totalMoneySaved = Math.round(
    yearValues.reduce((total, year) => total + year.netResult, 0)
  );

  //////////////////////////////////////
  ////////////////////// DATA FORMATTING
  //////////////////////////////////////

  const monthly = {
    costs: {
      userLicenses: {
        amount: Math.round(monthlyUserLicenses),
        totalCost: Math.round(monthlyUserLicenseCost),
      },
      addOns: {
        amount: Math.round(monthlyAddOnLicenses),
        totalCost: Math.round(monthlyAddOnLicenseCost),
      },
      totalCost: Math.round(monthlyTotalCost),
    },
    savings: {
      contractingSavings: Math.round(monthlyContractingSavings),
      toolReplacementSavings: Math.round(monthlyToolReplacementSavings),
      totalSavings: Math.round(monthlyTotalSavings),
      netSavings: Math.round(monthlyNetSavings),
    },
    hoursSaved: Math.round(monthlyHoursSaved),
    contractLeadTimeSavings: Math.round(monthlyContractLeadTimeSavings),
  };

  return {
    monthly,
    years,
    breakEvenMonth,
    hoursSavedPerYear,
    totalMoneySaved,
    contractLeadTimeDaysSavings,
    averageTimePerOutboundContractWithCurrentSolution,
    averageTimePerOutboundContractingWithPrecisely,
  };
};

export { useRoiCalculation };
