/** @jsxImportSource @emotion/react */
import { useTheme } from "../../hooks/useTheme";
import { CalculationCollapse } from "../CalculationCollapse";
import { PoweredByPrecisely } from "../PoweredByPrecisely";
import { Header4 } from "../text/Header4";
import { Paragraph } from "../text/Paragraph";
import { Section } from "./Section";
import fancy_blue from "../../assets/img/fancy_blue.svg";

const MainLayout = ({ children }) => {
  const { spacing, color } = useTheme();

  return (
    <div css={{ fontSize: "10px" }}>
      <div
        css={{
          background: color.primary.main,
          padding: spacing.XXL,
          marginBottom: spacing.XXL,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={fancy_blue}
          alt="Fancy graphics"
          css={{
            position: "absolute",
            width: `${850}px`,
            fill: "white",
            bottom: "-250px",
            left: "-100px",
            opacity: 0.7,
          }}
        />
        <div
          css={{
            width: "100%",
            maxWidth: "980px",
            margin: "auto",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              margin: "5rem auto",
            }}
          >
            <div
              css={{
                maxWidth: "60rem",
                padding: spacing["3XL"],
                position: "relative",
                margin: "auto",
                "&:before, &:after": {
                  position: "absolute",
                  content: '""',
                  height: "100%",
                  borderRadius: ".625rem",
                  width: "1.125rem",
                  top: 0,
                  borderTop: "2px solid white",
                  borderBottom: "2px solid white",
                },
                "&:before": {
                  left: "0",
                  borderLeft: "2px solid white",
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                },
                "&:after": {
                  right: 0,
                  borderRight: "2px solid white",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                },
              }}
            >
              <Header4
                css={{ color: "white", textAlign: "center", fontWeight: 400 }}
              >
                In their 2021 survey, EY saw that 97% of General Counsels face
                challenges securing budget as they fail to convince CEOs. That’s
                where this ROI calculator comes in.
              </Header4>
            </div>
          </div>
        </div>
      </div>
      <div
        css={{
          width: "100%",
          maxWidth: "980px",
          margin: "auto",
        }}
      >
        <div css={{ padding: spacing.XXL }}>
          <Section header="The CLM ROI Calculator" subHeader="Introduction">
            <Paragraph css={{ marginBottom: spacing.L }}>
              This calculator gives you the best estimations of costs, savings,
              and return on your investment that a contracting platform can
              bring to your organization. The calculations and estimations are
              based on various market reports and statistics as well as our
              Precisely CLM usage and product data (all sources can be found{" "}
              <a href="#how-we-calculate">at the bottom of this page</a>
              ).
            </Paragraph>
            <Paragraph>
              Below, you will find a number of questions to answer in relation
              to your current contracting processes, workflows, and
              technologies. You will be invited to tell us about the following
              aspects of your contracting processes:
            </Paragraph>
            <ol
              css={{
                listStyleType: "number",
                padding: "2rem",
                marginLeft: "1rem",
                fontSize: "1.5rem",
              }}
            >
              <li>
                <Paragraph>Your contract types and volumes</Paragraph>
              </li>
              <li>
                <Paragraph>
                  Your contract processes and software ecosystem
                </Paragraph>
              </li>
              <li>
                <Paragraph>Your contracting team and resources</Paragraph>
              </li>
            </ol>
            <Paragraph>
              These input parameters will result in a number of calculations,
              showcasing estimated costs, savings, and return on your
              investment.
            </Paragraph>
          </Section>
          {children}
          <CalculationCollapse />
          <PoweredByPrecisely />
        </div>
      </div>
    </div>
  );
};

export { MainLayout };
