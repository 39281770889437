/** @jsxImportSource @emotion/react */
import { useCallback, useState } from "react";
import { useTheme } from "../../hooks/useTheme";
import { Paragraph } from "../text/Paragraph";

const CheckboxInput = ({ id, name, isChecked, onChange, isDisabled }) => {
  const { spacing } = useTheme();

  return (
    <label
      css={{
        margin: `${spacing.XS} 0`,
        display: "flex",
        alignItems: "center",
        opacity: isDisabled ? 0.5 : 1,
        cursor: "pointer",
      }}
    >
      <input
        type="checkbox"
        name={id}
        css={{
          width: "18px",
          height: "18px",
          background: "rgba(242, 246, 250, 0.2)",
          borderRadius: "3px",
          marginRight: spacing.M,
        }}
        onChange={onChange}
        checked={isChecked}
      />

      <Paragraph>{name}</Paragraph>
    </label>
  );
};

const CheckboxGroupInput = ({ state, onChange }) => {
  const { spacing } = useTheme();

  const [isDoNotKnowChecked, setIsDoNotKnowChecked] = useState(false);

  const onCheckboxChange = useCallback(
    ({ target }) => {
      const updatedState = state.map((checkbox) => {
        if (checkbox.id === target.name) {
          return {
            ...checkbox,
            isChecked: target.checked,
          };
        } else {
          return checkbox;
        }
      });

      onChange(updatedState);
      setIsDoNotKnowChecked(false);
    },
    [state, onChange]
  );

  const onDoNotKnowChange = useCallback(
    ({ target }) => {
      const updatedState = state.map((checkbox) => ({
        ...checkbox,
        isChecked: false,
      }));

      onChange(updatedState);
      setIsDoNotKnowChecked(target.checked);
    },
    [state, onChange]
  );

  return (
    <div css={{ margin: `${spacing.M} 0` }}>
      {state.map(({ id, name, isChecked }) => (
        <CheckboxInput
          key={id}
          id={id}
          name={name}
          isChecked={isChecked}
          onChange={onCheckboxChange}
          isDisabled={isDoNotKnowChecked}
        />
      ))}

      <CheckboxInput
        id="do_not_know"
        name="Don't know"
        isChecked={isDoNotKnowChecked}
        onChange={onDoNotKnowChange}
      />
    </div>
  );
};

export { CheckboxGroupInput };
