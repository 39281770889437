/** @jsxImportSource @emotion/react */
import { Paragraph } from "./text/Paragraph";
import fancy_blue from "../assets/img/fancy_blue.svg";
import fancy_green from "../assets/img/fancy_green.svg";
import { useMemo } from "react";
import { Header1 } from "./text/Header1";
import { useTheme } from "../hooks/useTheme";
import { numberShortener } from "../utils/numberShortener";
import { Header4 } from "./text/Header4";

const InfoGraphic = ({ amount, unit, heading, description }) => {
  const { spacing, color } = useTheme();

  const randSeed = useMemo(() => Math.random(), []);
  const randInt = Math.floor(randSeed * 2) + 1;

  const formattedAmount = numberShortener.toK(amount);

  const diameter = 9;

  const textLengthFactor =
    formattedAmount.length < 5 ? 5 : formattedAmount.length;

  const fontSize = `${diameter / (textLengthFactor * 0.6)}rem`;

  const amountElement = (
    <div
      css={{
        width: `${diameter}rem`,
        height: `${diameter}rem`,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        background: color.primary.main,
        fontWeight: "bold",
        position: "relative",
        overflow: "hidden",
        marginRight: spacing.XL,
      }}
    >
      <div css={{ zIndex: 9 }}>
        <Header1
          css={{
            fontSize,
            lineHeight: fontSize,
            textAlign: "center",
            color: "white",
            marginBottom: "-6px",
          }}
        >
          {formattedAmount}
        </Header1>
        <Paragraph
          css={{
            fontSize: "12px",
            linHeight: "1.2rem",
            textAlign: "center",
            color: "white",
          }}
        >
          {unit}
        </Paragraph>
      </div>
      <img
        src={randInt === 1 ? fancy_blue : fancy_green}
        alt="Fancy graphics"
        css={{
          position: "absolute",
          width: `${350 - randSeed * 100}px`,
          fill: "white",
          left: `-${randSeed * 300}px`,
          transform: `rotate(${randSeed * 180}deg)`,
        }}
      />
    </div>
  );

  return (
    <div
      css={{
        display: "flex",
        marginBottom: "3rem",
      }}
    >
      {amountElement}
      <div css={{ flex: 1 }}>
        <Header4
          css={{
            marginTop: spacing.M,
            display: "flex",
            marginBottom: spacing.S,
          }}
        >
          {heading}
        </Header4>
        <Paragraph>{description}</Paragraph>
      </div>
    </div>
  );
};

export { InfoGraphic };
