/** @jsxImportSource @emotion/react */
import { ReactNode } from "react";
import { useTheme } from "../../hooks/useTheme";
import { Header4 } from "../text/Header4";
import { Paragraph } from "../text/Paragraph";

const Question = ({ header, description, children }: QuestionProps) => {
  const { spacing } = useTheme();

  return (
    <div css={{ marginBottom: "60px" }}>
      <Header4>{header}</Header4>
      {description && (
        <Paragraph css={{ marginTop: spacing.L }}>{description}</Paragraph>
      )}
      <div css={{ marginTop: spacing.L }}>{children}</div>
    </div>
  );
};

type QuestionProps = {
  header: string;
  description?: string;
  children: ReactNode;
};

export { Question };
