import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CalculatorExternal } from "./CalculatorExternal";
import { CalculatorInternal } from "./CalculatorInternal";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/internal" element={<CalculatorInternal />} />
      <Route path="/external" element={<CalculatorExternal />} />
      <Route path="/" element={<CalculatorExternal />} />
    </Routes>
  </BrowserRouter>
);

export default App;
